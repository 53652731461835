var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import toPairs from 'lodash/toPairs';
import { combineLatest, EMPTY, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import * as gql from 'typed-graphqlify';
import { CustomHttpParameterCodec } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiInfo, DEMO_RESOURCES_PROJECT, ServerRequestError } from '@modules/api';
import { DataGroup } from '@modules/charts';
import { modelFieldItemToDisplayField } from '@modules/customize';
import { DisplayFieldType, FieldType, getFieldDescriptionByType, LookupDisplayField, ValueFormat } from '@modules/fields';
import { forceModelId, ModelDescription, ModelFieldType, ModelRelation, PAGE_PARAM, processLegacyModelDescriptions, SEARCH_PARAM } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { Resource, ResourceName, ResourceType } from '@modules/projects';
import { HttpMethod, ListModelDescriptionQuery, ModelDescriptionQuery, QueryPagination, QueryService, QueryType, SqlQueryService } from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import { AppError, getTimezoneOffset, interpolateSqlContext, isAbsoluteUrl, isSet } from '@shared';
// TODO: Refactor import
import { modelFieldToRawListViewSettingsColumn } from '../../../customize/utils/common';
import { ModelResponse } from '../../data/model-response';
import { getQueryOptionsToParams, ResourceController } from '../../data/resource-controller';
import { aggregateGetResponse } from '../../utils/aggregate';
import { isResourceQueryCustom, prepareDataSourceColumnForGet } from '../../utils/common';
import { groupGetResponse } from '../../utils/group';
function mapGqlValue(value) {
    if (typeof value === 'string') {
        return gql.rawString(value);
    }
    else if (isArray(value)) {
        return value.map(function (item) { return mapGqlValue(item); });
    }
    else if (typeof value === 'object') {
        return gql.rawString(JSON.stringify(value));
    }
    else {
        return value;
    }
}
function cleanGqlName(name) {
    if (name == '_meta') {
        return '__meta';
    }
    return name.replace(/[^_a-zA-Z0-9]/gu, '_').replace(/^(\d)/, '_$1');
}
function mapGqlFilterItem(filter) {
    var result = {};
    var fieldItem = filter.field.reduce(function (acc, item, i) {
        var _a;
        var name = cleanGqlName(item);
        if (i == filter.field.length - 1) {
            acc[name] = {};
            return acc[name];
        }
        else {
            acc[name] = (_a = {}, _a['relation'] = {}, _a);
            return acc[name]['relation'];
        }
    }, result);
    var lookup = filter.lookup && isSet(filter.lookup.lookup) ? filter.lookup.param : 'eq';
    if (lookup == 'eq' && filter.value === null) {
        fieldItem['isNull'] = true;
    }
    else if (isSet(filter.value, true)) {
        fieldItem[lookup] = mapGqlValue(filter.value);
    }
    return filter.exclude ? { _not_: result } : result;
}
function lookupDisplayFieldToGql(lookup) {
    var root = {};
    return lookup.path.reduce(function (acc, item, i) {
        var name = cleanGqlName(item);
        if (i == lookup.path.length - 1) {
            acc[name] = {
                return: true
            };
            return root;
        }
        else {
            var next = {};
            acc[name] = {
                relation: next
            };
            return next;
        }
    }, root);
}
function aggregateDisplayFieldToGql(aggregate) {
    var root = {};
    return aggregate.path.reduce(function (acc, item, i) {
        var name = cleanGqlName(item);
        if (i == aggregate.path.length - 1) {
            acc[name] = {
                aggregate: __assign({ func: aggregate.func }, (isSet(aggregate.column) && {
                    attr: gql.rawString(aggregate.column)
                }))
            };
            return root;
        }
        else {
            var next = {};
            acc[name] = {
                relation: next
            };
            return next;
        }
    }, root);
}
function getLookupValue(lookup, path) {
    return path.reduce(function (acc, item, i) {
        var name = cleanGqlName(item);
        if (!acc || !acc[name]) {
            return;
        }
        if (i == path.length - 1) {
            return acc[name].value;
        }
        else {
            return acc[name].related;
        }
    }, lookup);
}
function getLookupAggregated(lookup, path) {
    return path.reduce(function (acc, item, i) {
        var name = cleanGqlName(item);
        if (!acc || !acc[name]) {
            return;
        }
        if (i == path.length - 1) {
            return acc[name].aggregated;
        }
        else {
            return acc[name].related;
        }
    }, lookup);
}
var JetBridgeResourceController = /** @class */ (function (_super) {
    __extends(JetBridgeResourceController, _super);
    function JetBridgeResourceController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filtersExcludable = true;
        _this.filtersLookups = true;
        _this.relationFilter = true;
        return _this;
    }
    JetBridgeResourceController.prototype.init = function () {
        this.mode = this.initService(ROUTE_ADMIN_MODE);
        this.http = this.initService(HttpClient);
        this.apiService = this.initService(ProjectApiService);
        this.queryService = this.initService(QueryService);
        this.sqlQueryService = this.initService(SqlQueryService);
        this.queryTokensService = this.initService(QueryTokensService);
        this.parameterCodec = this.initService(CustomHttpParameterCodec);
    };
    JetBridgeResourceController.prototype.supportedQueryTypes = function (resource, queryClass) {
        if (resource.name == ResourceName.MongoDB) {
            return [QueryType.Simple];
        }
        else {
            return [QueryType.Simple, QueryType.SQL];
        }
    };
    JetBridgeResourceController.prototype.getHeaders = function (resource) {
        var headers = new HttpHeaders();
        var childProjectName = window['project_has_parent'] ? window['project'] : undefined;
        headers = this.apiService.setHeadersToken(headers, childProjectName);
        headers = this.apiService.setHeadersBridgeSettings(headers, resource);
        headers = this.setStickSessionHeader(headers, resource);
        return headers;
    };
    JetBridgeResourceController.prototype.getResourceUID = function (resource, separator) {
        return [resource.demo ? DEMO_RESOURCES_PROJECT : window['project'], resource.environment, resource.uniqueName].join(separator);
    };
    JetBridgeResourceController.prototype.setStickSessionHeader = function (headers, resource) {
        var stickSession = resource.params['bridge_settings'] ? this.getResourceUID(resource, '|') : undefined;
        if (stickSession) {
            headers = headers.set('X-Stick-Session', stickSession);
        }
        return headers;
    };
    JetBridgeResourceController.prototype.getApiInfo = function (resource) {
        var url = resource.params['url'];
        if (!isSet(url)) {
            return of(undefined);
        }
        var headers = new HttpHeaders();
        headers = this.setStickSessionHeader(headers, resource);
        return this.http.get(url, { headers: headers }).pipe(map(function (result) { return new ApiInfo().deserialize(result); }), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.checkApiInfo = function (resource) {
        if (resource.apiInfo ||
            !(resource.type == ResourceType.JetBridge || resource.isSynced() || resource.hasCollectionSync())) {
            return of(resource);
        }
        return this.getApiInfo(resource).pipe(map(function (apiInfo) {
            if (apiInfo) {
                resource.apiInfo = apiInfo;
            }
            return resource;
        }));
    };
    JetBridgeResourceController.prototype.checkResource = function (typeItem, params) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var headers = new HttpHeaders();
            var url = params['url'];
            if (params && params['bridge_settings']) {
                url += 'model_descriptions/';
                var resource = new Resource();
                resource.params = params;
                headers = _this.getHeaders(resource);
            }
            return _this.http.get(url, { headers: headers });
        }), map(function (result) {
            if (result['version'] == undefined) {
                throwError(false);
                return;
            }
            return true;
        }), catchError(function (e) {
            var serverError = new ServerRequestError(e);
            if (serverError.status == 0) {
                serverError = new ServerRequestError({
                    non_field_errors: undefined,
                    url: ['API is not reachable. Is it running? If so, you might have a CORS configuration issue.']
                });
            }
            return throwError(serverError);
        }), publishLast(), refCount());
    };
    // getModelDescriptionGetParameters(modelDescription: ModelDescription): ParameterField[] {
    //   return modelDescription.dbFields
    //     .reduce((acc, field) => {
    //       const fieldDescription = getFieldDescriptionByType(field.field);
    //       const params = { ...fieldDescription.defaultParams, ...fieldDescription.forceParams };
    //
    //       fieldDescription.lookups.forEach(lookup => {
    //         const filterName = serializeFieldParamName(field.name, lookup.type.lookup);
    //
    //         acc.push({
    //           group: field.verboseName || field.name,
    //           name: filterName,
    //           verbose_name: [field.verboseName || field.name, lookup.type.verboseName].join(' '),
    //           field: lookup.field,
    //           required: false,
    //           params: params
    //         });
    //
    //         // Exclude parameters
    //         const excludeName = serializeFieldParamName(field.name, lookup.type.lookup, true);
    //
    //         acc.push({
    //           group: field.verboseName || field.name,
    //           name: excludeName,
    //           verbose_name: ['exclude', field.verboseName || field.name, lookup.type.verboseName].join(' '),
    //           field: lookup.field,
    //           required: false,
    //           params: params
    //         });
    //       });
    //
    //       return acc;
    //     }, [])
    //     .map(item => new ParameterField().deserialize(item));
    // }
    JetBridgeResourceController.prototype.supportModelDescriptionManagement = function (resource) {
        return resource.typeItem && [ResourceName.JetDatabase].includes(resource.typeItem.name);
    };
    JetBridgeResourceController.prototype.discoverConnection = function (resource) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource('discover/connection/', resource);
            var headers = _this.getHeaders(resource);
            return _this.http.get(projectUrl, {
                headers: headers,
                observe: 'response'
            });
        }), this.apiService.processApiResponse(), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.discoverTables = function (resource) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource('discover/tables/', resource);
            var headers = _this.getHeaders(resource);
            return _this.http.get(projectUrl, {
                headers: headers,
                observe: 'response'
            });
        }), this.apiService.processApiResponse(), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelDescriptionGet = function (resource, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource('model_descriptions/', resource);
            var headers = _this.getHeaders(resource);
            var params = __assign({ cid: _this.getResourceUID(resource, '/') }, (draft && { draft: '1' }));
            return _this.http.get(projectUrl, { headers: headers, params: params, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            return processLegacyModelDescriptions(result);
        }), map(function (result) {
            return result.map(function (item) {
                var schemas = resource.typeItem.name == ResourceName.BigQuery;
                var instance = new ModelDescription().deserialize(item, schemas);
                instance.resource = resource.uniqueName;
                instance.fields
                    .filter(function (field) {
                    return ([FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(field.item.field) &&
                        field.item.params['resource'] == '{{resource}}');
                })
                    .forEach(function (field) {
                    field.item.params['resource'] = resource.uniqueName;
                });
                instance.fields
                    .filter(function (field) {
                    return (field.item.field == FieldType.RelatedModel &&
                        field.item.params['related_model'] &&
                        isSet(field.item.params['related_model']['model']) &&
                        !field.item.params['related_model']['model'].includes('.'));
                })
                    .forEach(function (field) {
                    field.item.params['related_model']['model'] = [
                        resource.uniqueName,
                        field.item.params['related_model']['model']
                    ].join('.');
                });
                instance.getQuery = new ListModelDescriptionQuery();
                instance.getQuery.queryType = QueryType.Simple;
                instance.getQuery.simpleQuery = new instance.getQuery.simpleQueryClass();
                instance.getQuery.simpleQuery.model = instance.model;
                // instance.getParameters = this.getModelDescriptionGetParameters(instance);
                instance.searchQuery = new ListModelDescriptionQuery();
                instance.searchQuery.queryType = QueryType.Simple;
                instance.searchQuery.simpleQuery = new instance.searchQuery.simpleQueryClass();
                instance.searchQuery.simpleQuery.model = instance.model;
                instance.getDetailQuery = new ModelDescriptionQuery();
                instance.getDetailQuery.queryType = QueryType.Simple;
                instance.getDetailQuery.simpleQuery = new instance.getDetailQuery.simpleQueryClass();
                instance.getDetailQuery.simpleQuery.model = instance.model;
                // instance.getDetailParameters = this.getModelDescriptionGetParameters(instance);
                instance.getDetailParametersUseDefaults = true;
                if (resource.type == ResourceType.JetBridge && !instance.isView) {
                    instance.createQuery = new ModelDescriptionQuery();
                    instance.createQuery.queryType = QueryType.Simple;
                    instance.createQuery.simpleQuery = new instance.createQuery.simpleQueryClass();
                    instance.createQuery.simpleQuery.model = instance.model;
                    instance.createParametersUseDefaults = true;
                    instance.updateQuery = new ModelDescriptionQuery();
                    instance.updateQuery.queryType = QueryType.Simple;
                    instance.updateQuery.simpleQuery = new instance.updateQuery.simpleQueryClass();
                    instance.updateQuery.simpleQuery.model = instance.model;
                    instance.updateParametersUseDefaults = true;
                    instance.deleteQuery = new ModelDescriptionQuery();
                    instance.deleteQuery.queryType = QueryType.Simple;
                    instance.deleteQuery.simpleQuery = new instance.deleteQuery.simpleQueryClass();
                    instance.deleteQuery.simpleQuery.model = instance.model;
                    instance.deleteParametersUseDefaults = true;
                }
                else {
                    instance.createQuery = undefined;
                    instance.updateQuery = undefined;
                    instance.deleteQuery = undefined;
                }
                instance.siblingsQuery = new ModelDescriptionQuery();
                instance.siblingsQuery.queryType = QueryType.Simple;
                instance.siblingsQuery.simpleQuery = new instance.siblingsQuery.simpleQueryClass();
                instance.siblingsQuery.simpleQuery.model = instance.model;
                instance.aggregateQuery = new ModelDescriptionQuery();
                instance.aggregateQuery.queryType = QueryType.Simple;
                instance.aggregateQuery.simpleQuery = new instance.aggregateQuery.simpleQueryClass();
                instance.aggregateQuery.simpleQuery.model = instance.model;
                // instance.aggregateParameters = this.getModelDescriptionGetParameters(instance);
                instance.groupQuery = new ModelDescriptionQuery();
                instance.groupQuery.queryType = QueryType.Simple;
                instance.groupQuery.simpleQuery = new instance.groupQuery.simpleQueryClass();
                instance.groupQuery.simpleQuery.model = instance.model;
                // instance.groupParameters = this.getModelDescriptionGetParameters(instance);
                return instance;
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.setModelDescriptionRelationOverrides = function (resource, items, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource('model_descriptions/relationship_overrides/', resource);
            var headers = _this.getHeaders(resource);
            var params = __assign({}, (draft && { draft: '1' }));
            var data = items.map(function (item) {
                return {
                    model: item.modelDescription.model,
                    relations: item.relations.map(function (relation) {
                        return {
                            direction: relation.direction,
                            local_field: relation.localField,
                            related_model: relation.relatedModel,
                            related_field: relation.relatedField
                        };
                    })
                };
            });
            return _this.http.post(projectUrl, data, { headers: headers, params: params, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) { return result.map(function (item) { return new ModelRelation().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.serializeModelDescriptionField = function (modelDescription, field) {
        var fieldDescription = getFieldDescriptionByType(field.field);
        if (!field.dbField) {
            if (fieldDescription.name == FieldType.Number) {
                var valueFormat = field.params && field.params['value_format']
                    ? new ValueFormat().deserialize(field.params['value_format'])
                    : undefined;
                if (valueFormat && isSet(valueFormat.numberFraction) && valueFormat.numberFraction != 0) {
                    field.dbField = 'FloatField';
                }
            }
            else if (fieldDescription.name == FieldType.Select) {
                field.dbField = 'CharField';
            }
        }
        if (field.params && field.params['related_model'] && field.field != FieldType.RelatedModel) {
            delete field.params['related_model'];
        }
        return {
            name: field.name,
            field: fieldDescription.jetBridgeTypes[0],
            db_field: field.dbField,
            primary_key: modelDescription.primaryKeyField == field.name,
            null: field.null,
            default_type: field.defaultType,
            default_value: field.defaultValue,
            params: field.params,
            data_source_field: field.field,
            data_source_name: field.verboseName,
            data_source_params: field.params
        };
    };
    JetBridgeResourceController.prototype.modelDescriptionCreate = function (resource, modelDescription) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource('tables/', resource);
            var headers = _this.getHeaders(resource);
            var data = {
                name: modelDescription.dbTable,
                columns: modelDescription.dbFields.map(function (field) { return _this.serializeModelDescriptionField(modelDescription, field); }),
                data_source_name: modelDescription.verboseName,
                data_source_name_plural: modelDescription.verboseNamePlural
            };
            return _this.http.post(projectUrl, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelDescriptionDelete = function (resource, modelDescription) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource("tables/" + modelDescription.dbTable + "/", resource);
            var headers = _this.getHeaders(resource);
            return _this.http.delete(projectUrl, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelDescriptionFieldCreate = function (resource, modelDescription, field) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource("tables/" + modelDescription.dbTable + "/columns/", resource);
            var headers = _this.getHeaders(resource);
            var data = _this.serializeModelDescriptionField(modelDescription, field);
            return _this.http.post(projectUrl, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelDescriptionFieldUpdate = function (resource, modelDescription, name, field) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource("tables/" + modelDescription.dbTable + "/columns/" + name + "/", resource);
            var headers = _this.getHeaders(resource);
            var data = _this.serializeModelDescriptionField(modelDescription, field);
            return _this.http.patch(projectUrl, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelDescriptionFieldDelete = function (resource, modelDescription, name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var projectUrl = _this.apiService.methodURLForProjectResource("tables/" + modelDescription.dbTable + "/columns/" + name + "/", resource);
            var headers = _this.getHeaders(resource);
            return _this.http.delete(projectUrl, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.getSql = function (resource, query, parameters, params, body, columns, paginate, aggregate, group) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        if (paginate === void 0) { paginate = true; }
        if (!query.sqlQuery) {
            return of(undefined);
        }
        return this.checkApiInfo(resource).pipe(switchMap(function () {
            var userQuery = isResourceQueryCustom(resource, query.queryType);
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelGetTokens(params, parameters, userQuery), _this.queryTokensService.paginationTokens(QueryPagination.Page, params));
            var sql = query.sqlQuery.query.trim().replace(/;+$/, '');
            var newQuery = query.sqlQuery.version &&
                query.sqlQuery.version >= 2 &&
                resource.apiInfo &&
                resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.0', jetDjango: '1.1.5' });
            if (newQuery) {
                var sorting = tokens.sorting && tokens.sorting.field !== undefined
                    ? [(tokens.sorting.asc ? '' : '-') + tokens.sorting.field]
                    : undefined;
                var queryColumns = columns.map(function (item) {
                    var fieldDescription = getFieldDescriptionByType(item.field);
                    return {
                        name: item.name,
                        data_type: fieldDescription.jetBridgeTypes[0]
                    };
                });
                var filters = toPairs(tokens.params).map(function (_a) {
                    var k = _a[0], v = _a[1];
                    return {
                        name: k,
                        value: v
                    };
                });
                if (isSet(tokens.search)) {
                    filters.push({
                        name: SEARCH_PARAM,
                        value: tokens.search
                    });
                }
                return _this.sql(resource, sql, __assign({ tokens: tokens }, (paginate &&
                    tokens.paging && {
                    offset: tokens.paging.offset,
                    limit: tokens.paging.limit,
                    count: true
                }), { orderBy: sorting, columns: queryColumns, filters: filters, aggregate: aggregate, groups: group, version: query.sqlQuery.version })).pipe(map(function (result) {
                    var data = {
                        results: result.toObject()
                    };
                    var response = _this.createGetResponse().deserialize(__assign({}, data, { per_page: data['limit'] }));
                    response.results.forEach(function (item) {
                        item.deserializeAttributes(columns);
                    });
                    response.count = result.count;
                    response.hasMore =
                        response.count !== undefined
                            ? tokens.paging.offset + result.data.length < response.count
                            : !!result.data.length;
                    if (!isSet(response.perPage) &&
                        response.hasMore &&
                        isSet(tokens.paging.limit) &&
                        result.data.length < tokens.paging.limit) {
                        response.perPage = result.data.length;
                    }
                    return response;
                }), _this.apiService.catchApiError(), publishLast(), refCount());
            }
            else {
                var wrapperSql = "SELECT * FROM (" + sql + ") __jet_q";
                var countSql = "SELECT count(*) FROM (" + sql + ") __jet_q";
                if (tokens.sorting && tokens.sorting.field !== undefined) {
                    if (resource.typeItem.name == ResourceName.PostgreSQL) {
                        wrapperSql += " ORDER BY \"" + tokens.sorting.field + "\" " + (tokens.sorting.asc ? 'ASC' : 'DESC');
                    }
                    else {
                        wrapperSql += " ORDER BY " + tokens.sorting.field + " " + (tokens.sorting.asc ? 'ASC' : 'DESC');
                    }
                }
                if (paginate && tokens.paging) {
                    // Reference: https://stackoverflow.com/a/24046664
                    if (resource.typeItem.name == ResourceName.MicrosoftSQL) {
                        wrapperSql += " ORDER BY (SELECT NULL) OFFSET " + tokens.paging.offset + " ROWS FETCH NEXT " + tokens.paging.limit + " ROWS ONLY";
                    }
                    else if (resource.typeItem.name == ResourceName.Oracle) {
                        wrapperSql += " OFFSET " + tokens.paging.offset + " ROWS FETCH NEXT " + tokens.paging.limit + " ROWS ONLY";
                    }
                    else {
                        wrapperSql += " LIMIT " + tokens.paging.limit + " OFFSET " + tokens.paging.offset;
                    }
                }
                return combineLatest(_this.sql(resource, wrapperSql, { tokens: tokens, version: query.sqlQuery.version }), _this.sql(resource, countSql, { tokens: tokens, version: query.sqlQuery.version }).pipe(catchError(function () { return of(undefined); }))).pipe(map(function (_a) {
                    var result = _a[0], countResult = _a[1];
                    var data = {
                        results: result.toObject()
                    };
                    var response = _this.createGetResponse().deserialize(__assign({}, data, { per_page: data['limit'] }));
                    response.results.forEach(function (item) {
                        item.deserializeAttributes(columns);
                    });
                    response.count = countResult ? countResult.data[0][0] : undefined;
                    response.hasMore =
                        response.count !== undefined
                            ? tokens.paging.offset + response.results.length < response.count
                            : !!result.data.length;
                    if (!isSet(response.perPage) &&
                        response.hasMore &&
                        isSet(tokens.paging.limit) &&
                        result.data.length < tokens.paging.limit) {
                        response.perPage = result.data.length;
                    }
                    return response;
                }), _this.apiService.catchApiError(), publishLast(), refCount());
            }
        }));
    };
    JetBridgeResourceController.prototype.getSimple = function (resource, query, params, body, columns, paginate) {
        var _this = this;
        if (columns === void 0) { columns = []; }
        if (paginate === void 0) { paginate = true; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.modelUrlForProjectResource(query.simpleQuery.model, resource);
            var headers = _this.getHeaders(resource);
            var page = params[PAGE_PARAM] || 1;
            var queryParams = pickBy(params, function (v, k) { return v !== null && v !== undefined; });
            var httpParams = new HttpParams({
                fromObject: __assign({}, queryParams
                // tz: getTimezoneOffset()
                ),
                encoder: _this.parameterCodec
            });
            if (body) {
                headers = headers.set('X-HTTP-Method-Override', HttpMethod.GET);
                return _this.http
                    .request('post', url, {
                    headers: headers,
                    params: httpParams,
                    body: body,
                    observe: 'response'
                })
                    .pipe(_this.apiService.processApiResponse(), map(function (result) {
                    var data = result;
                    if (result['results'] === undefined) {
                        data = {
                            results: data
                        };
                    }
                    else if (isSet(data['num_pages'])) {
                        data['has_more'] = page < data['num_pages'];
                    }
                    return _this.createGetResponse().deserialize(data, query.simpleQuery.model, undefined);
                }), tap(function (response) {
                    response.results.forEach(function (item) {
                        item.deserializeAttributes(columns);
                    });
                    return response;
                }), _this.apiService.catchApiError(), publishLast(), refCount());
            }
            else {
                return _this.http.get(url, { headers: headers, params: httpParams, observe: 'response' }).pipe(_this.apiService.processApiResponse(), map(function (result) {
                    var data = result;
                    if (data['results'] === undefined) {
                        data = {
                            results: data
                        };
                    }
                    else if (isSet(data['num_pages'])) {
                        data['has_more'] = page < data['num_pages'];
                    }
                    return _this.createGetResponse().deserialize(data, query.simpleQuery.model, undefined);
                }), tap(function (response) {
                    response.results.forEach(function (item) {
                        item.deserializeAttributes(columns);
                    });
                }), _this.apiService.catchApiError(), publishLast(), refCount());
            }
        }));
    };
    JetBridgeResourceController.prototype.isGetAdvSupported = function (resource) {
        return (resource &&
            resource.apiInfo &&
            resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.2.8', jetDjango: '1.4.3' }) &&
            resource.apiInfo.storeAvailable);
    };
    JetBridgeResourceController.prototype.getSimpleAdv = function (resource, query, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var draft = this.mode == AdminMode.Builder;
        var model = cleanGqlName(query.simpleQuery.model);
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var _a;
            var url = _this.apiService.methodURLForProjectResource('graphql/', resource);
            var headers = _this.getHeaders(resource);
            var queryParams = __assign({}, (draft && { draft: '1' }));
            var params = {};
            var dataColumns = (options.columns || []).filter(function (item) { return item.type == DisplayFieldType.Base; });
            var lookupColumns = (options.columns || []).filter(function (item) { return item.type == DisplayFieldType.Lookup; });
            var aggregateColumns = (options.columns || []).filter(function (item) { return item.type == DisplayFieldType.Aggregate; });
            if (options.paging) {
                params['pagination'] = __assign({ page: options.paging.page || 1 }, (isSet(options.paging.limit) && { limit: options.paging.limit }));
            }
            if (options.filters && options.filters.length) {
                params['filters'] = options.filters.map(function (item) { return mapGqlFilterItem(item); });
            }
            if (isSet(options.search)) {
                params['search'] = { query: gql.rawString(options.search) };
            }
            if (options.sort && options.sort.length) {
                params['sort'] = options.sort.map(function (item) {
                    var _a;
                    var name = cleanGqlName(item.field);
                    return _a = {},
                        _a[name] = {
                            descending: !!item.desc
                        },
                        _a;
                });
            }
            var relatedColumns = dataColumns
                .filter(function (item) { return item.field == FieldType.RelatedModel; })
                .filter(function (item) { return isSet(item.params['related_model']) && isSet(item.params['related_model']['model']); })
                .map(function (relatedColumn) {
                var relatedModelId = forceModelId(relatedColumn.params['related_model']['model'], resource.uniqueName);
                var relatedModelDescription = isSet(relatedModelId) && options.modelDescriptions
                    ? options.modelDescriptions.find(function (item) { return item.isSame(relatedModelId); })
                    : undefined;
                var nameField = relatedColumn.params['custom_display_field'] ||
                    (relatedModelDescription ? relatedModelDescription.displayField : undefined);
                return {
                    column: relatedColumn,
                    nameField: nameField
                };
            })
                .filter(function (item) { return isSet(item.nameField); });
            var lookups = relatedColumns
                .map(function (item) { return new LookupDisplayField({ path: [item.column.name, item.nameField] }); })
                .map(function (item) { return lookupDisplayFieldToGql(item); }).concat(lookupColumns.map(function (item) { return lookupDisplayFieldToGql(item); }), aggregateColumns.map(function (item) { return aggregateDisplayFieldToGql(item); }));
            if (lookups.length) {
                params['lookups'] = lookups;
            }
            var modelData = {};
            var modelAttrs = dataColumns.reduce(function (acc, item) {
                var name = cleanGqlName(item.name);
                acc[name] = gql.types.string;
                return acc;
            }, {});
            if (keys(modelAttrs).length) {
                modelData['attrs'] = modelAttrs;
            }
            else {
                modelData['allAttrs'] = gql.types.custom();
            }
            if (lookups.length) {
                modelData['lookups'] = gql.types.custom();
            }
            var modelFields = {
                data: modelData,
                pagination: __assign({ limit: gql.types.number, offset: gql.types.number, page: gql.types.number }, (options.paging && options.paging.disableCount
                    ? undefined
                    : {
                        count: gql.types.number,
                        hasMore: gql.types.boolean
                    }))
            };
            var getRecordsQuery = gql.query('GetRecords', (_a = {},
                _a[model] = keys(params).length ? gql.params(params, modelFields) : modelFields,
                _a));
            var data = { query: getRecordsQuery.toString(), validate: false };
            return _this.http
                .post(url, data, {
                headers: headers,
                params: queryParams,
                observe: 'response'
            })
                .pipe(_this.apiService.processApiResponse(), map(function (result) {
                if (result.errors) {
                    throw new AppError(result.errors[0]);
                }
                if (!result.data[model]) {
                    throw new AppError("Collection \"" + model + "\" not found");
                }
                var results = result.data[model].data.map(function (item) {
                    var attrs;
                    if (dataColumns.length) {
                        attrs = fromPairs(dataColumns.map(function (column) {
                            var value = item.attrs[cleanGqlName(column.name)];
                            return [column.name, value];
                        }));
                    }
                    else {
                        attrs = item.allAttrs;
                    }
                    (item.lookups || [])
                        .slice(relatedColumns.length, relatedColumns.length + lookupColumns.length)
                        .forEach(function (lookup, i) {
                        var lookupColumn = lookupColumns[i];
                        attrs[lookupColumn.name] = getLookupValue(lookup, lookupColumn.path);
                    });
                    (item.lookups || [])
                        .slice(relatedColumns.length + lookupColumns.length, relatedColumns.length + lookupColumns.length + aggregateColumns.length)
                        .forEach(function (aggregate, i) {
                        var aggregateColumn = aggregateColumns[i];
                        attrs[aggregateColumn.name] = getLookupAggregated(aggregate, aggregateColumn.path);
                    });
                    return attrs;
                });
                var relations = result.data[model].data.map(function (item) {
                    return (item.lookups || [])
                        .slice(0, relatedColumns.length)
                        .map(function (lookup, i) {
                        var column = relatedColumns[i].column;
                        var nameField = relatedColumns[i].nameField;
                        if (!lookup[column.name] ||
                            !lookup[column.name].related ||
                            !lookup[column.name].related[nameField]) {
                            return;
                        }
                        return {
                            path: [column.name],
                            field: nameField,
                            value: lookup[column.name].related[nameField].value
                        };
                    })
                        .filter(function (relation) { return relation; });
                });
                var pagination = result.data[model].pagination;
                return _this.createGetResponse().deserialize({
                    results: results,
                    relations: relations,
                    count: pagination.count,
                    per_page: pagination.limit,
                    has_more: pagination.hasMore
                }, query.simpleQuery.model, undefined);
            }), tap(function (response) {
                var columns = dataColumns.concat(lookupColumns, aggregateColumns);
                if (columns.length) {
                    response.results.forEach(function (item) {
                        item.deserializeAttributes(columns);
                    });
                }
            }), _this.apiService.catchApiError(), publishLast(), refCount());
        }));
    };
    JetBridgeResourceController.prototype.modelGetSimple = function (resource, modelDescription, params, body, paginate) {
        if (paginate === void 0) { paginate = true; }
        return this.getSimple(resource, modelDescription.getQuery, params, body, modelDescription.dbFields, paginate).pipe(map(function (response) {
            if (!response) {
                return;
            }
            response.results.forEach(function (item) {
                item.setUp(modelDescription);
                item.deserializeAttributes(modelDescription.dbFields);
            });
            return response;
        }));
    };
    JetBridgeResourceController.prototype.modelGetSimpleAdv = function (resource, modelDescription, options) {
        if (options === void 0) { options = {}; }
        return this.getSimpleAdv(resource, modelDescription.getQuery, __assign({}, options, { columns: options.columns && options.columns.length
                ? options.columns
                : modelDescription.dbFields
                    .map(function (item) { return modelFieldItemToDisplayField(item); })
                    .map(function (field) { return prepareDataSourceColumnForGet(resource, modelDescription, field); }) })).pipe(map(function (response) {
            if (!response) {
                return;
            }
            response.results.forEach(function (item) {
                item.setUp(modelDescription);
                item.deserializeAttributes(modelDescription.dbFields);
            });
            return response;
        }));
    };
    JetBridgeResourceController.prototype.modelGetSql = function (resource, modelDescription, params, body) {
        return this.getSql(resource, modelDescription.getQuery, modelDescription.getParameters, params, body, modelDescription.dbFields).pipe(map(function (response) {
            if (!response) {
                return;
            }
            response.results.forEach(function (item) {
                item.setUp(modelDescription);
                item.deserializeAttributes(modelDescription.dbFields);
            });
            return response;
        }));
    };
    JetBridgeResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        params = params || {};
        if (modelDescription.getQuery.queryType == QueryType.SQL) {
            return this.modelGetSql(resource, modelDescription, params, body);
        }
        else {
            return this.modelGetSimple(resource, modelDescription, params, body);
        }
    };
    JetBridgeResourceController.prototype.modelGetAdv = function (resource, modelDescription, options) {
        if (options === void 0) { options = {}; }
        if (modelDescription.getQuery.queryType == QueryType.SQL) {
            var params = getQueryOptionsToParams(options);
            return this.modelGetSql(resource, modelDescription, params);
        }
        else {
            return this.modelGetSimpleAdv(resource, modelDescription, options);
        }
    };
    JetBridgeResourceController.prototype.getDetailSql = function (resource, query, parameters, params, columns) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        if (!query.sqlQuery) {
            return of(undefined);
        }
        var userQuery = isResourceQueryCustom(resource, query.queryType);
        var tokens = this.queryTokensService.mergeTokens(this.queryTokensService.generalTokens(), this.queryTokensService.modelGetDetailTokens(params, parameters, userQuery), params);
        return this.sql(resource, query.sqlQuery.query, { tokens: tokens, version: query.sqlQuery.version }).pipe(map(function (response) {
            if (!response || !response.data.length) {
                return;
            }
            var model = _this.createModel().deserialize(undefined, response.toObject()[0]);
            model.deserializeAttributes(columns);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelGetDetailSimple = function (resource, modelDescription, idField, id, params) {
        var _a, _b;
        if (modelDescription && modelDescription.resource == 'messages_api') {
            params = __assign({}, params, (_a = {}, _a[idField] = id, _a));
        }
        else {
            if (isSet(id)) {
                params = __assign({}, params, (_b = {}, _b[idField] = id, _b));
            }
        }
        return this.modelGetSimple(resource, modelDescription, params).pipe(map(function (result) { return result.results[0]; }));
    };
    JetBridgeResourceController.prototype.modelGetDetailSql = function (resource, modelDescription, idField, id, params) {
        return this.getDetailSql(resource, modelDescription.getDetailQuery, modelDescription.getDetailParametersOrDefaults, params, modelDescription.dbFields).pipe(map(function (result) {
            if (!result) {
                return;
            }
            result.setUp(modelDescription);
            result.deserializeAttributes(modelDescription.dbFields);
            return result;
        }));
    };
    JetBridgeResourceController.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        params = params || {};
        if (modelDescription.getDetailQuery) {
            if (modelDescription.getDetailQuery.queryType == QueryType.SQL) {
                return this.modelGetDetailSql(resource, modelDescription, idField, id, params);
            }
            else {
                return this.modelGetDetailSimple(resource, modelDescription, idField, id, params);
            }
        }
        else if (modelDescription.getQuery) {
            params = __assign({}, params);
            if (isSet(modelDescription.primaryKeyField)) {
                params[idField] = id;
            }
            return (modelDescription.getQuery.queryType == QueryType.SQL
                ? this.modelGetSql(resource, modelDescription, params)
                : this.modelGetSimple(resource, modelDescription, params)).pipe(map(function (result) {
                if (!result || !result.results.length) {
                    return;
                }
                return result.results[0];
            }));
        }
    };
    JetBridgeResourceController.prototype.modelGetDetailAdv = function (resource, modelDescription, idField, id, options) {
        if (options === void 0) { options = {}; }
        var params = getQueryOptionsToParams(options);
        if (modelDescription.getDetailQuery && !this.isGetAdvSupported(resource)) {
            if (modelDescription.getDetailQuery.queryType == QueryType.SQL) {
                return this.modelGetDetailSql(resource, modelDescription, idField, id, params);
            }
            else {
                return this.modelGetDetailSimple(resource, modelDescription, idField, id, params);
            }
        }
        else if (modelDescription.getQuery) {
            if (isSet(modelDescription.primaryKeyField)) {
                params[idField] = id;
            }
            return (modelDescription.getQuery.queryType == QueryType.SQL
                ? this.modelGetSql(resource, modelDescription, params)
                : this.modelGetSimpleAdv(resource, modelDescription, options)).pipe(map(function (result) {
                if (!result || !result.results.length) {
                    return;
                }
                return result.results[0];
            }));
        }
    };
    JetBridgeResourceController.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.modelUrlForProjectResource(modelDescription.model, resource);
            var headers = _this.getHeaders(resource);
            var data = modelInstance.serialize(fields);
            return _this.http.post(url, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            var instance = _this.createModel().deserialize(modelDescription.model, result);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelCreateBulk = function (resource, modelDescription, modelInstances, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.actionUrlForProjectResource(modelDescription.model, 'bulk_create', resource);
            var headers = _this.getHeaders(resource);
            var data = modelInstances.map(function (item) { return item.serialize(fields); });
            return _this.http.post(url, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            var instance = _this.createModel().deserialize(modelDescription.model, result);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.detailModelUrlForProjectResource(modelDescription.model, modelInstance.initialPrimaryKey, resource);
            var headers = _this.getHeaders(resource);
            return _this.http.patch(url, modelInstance.serialize(fields), { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            var instance = _this.createModel().deserialize(modelDescription.model, result);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.detailModelUrlForProjectResource(modelDescription.model, modelInstance.initialPrimaryKey, resource);
            var headers = _this.getHeaders(resource);
            return _this.http.delete(url, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelReorder = function (resource, modelDescription, forward, segmentFrom, segmentTo, item, segmentByOrderingField) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.actionUrlForProjectResource(modelDescription.model, 'reorder', resource);
            var headers = _this.getHeaders(resource);
            var data = {
                ordering_field: modelDescription.orderingField,
                forward: forward,
                segment_from: segmentFrom,
                segment_to: segmentTo,
                item: item,
                segment_by_ordering_field: segmentByOrderingField
            };
            return _this.http.post(url, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            var instance = _this.createModel().deserialize(modelDescription.model, result);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.modelResetOrder = function (resource, modelDescription, ordering, valueOrdering) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.actionUrlForProjectResource(modelDescription.model, 'reset_order', resource);
            var headers = _this.getHeaders(resource);
            var data = {
                ordering_field: modelDescription.orderingField,
                ordering: ordering,
                value_ordering: valueOrdering
            };
            return _this.http.post(url, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            var instance = _this.createModel().deserialize(modelDescription.model, result);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.aggregateSimple = function (resource, model, yFunc, yColumn, params) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.actionUrlForProjectResource(model, 'aggregate', resource);
            var headers = _this.getHeaders(resource);
            var httpParams = new HttpParams({
                fromObject: __assign({}, (params || {}), (isSet(yFunc) ? { _y_func: yFunc } : {}), (isSet(yColumn) ? { _y_column: yColumn } : {})
                // tz: getTimezoneOffset()
                ),
                encoder: _this.parameterCodec
            });
            return _this.http.get(url, { headers: headers, params: httpParams, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) { return result['y_func']; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.getSimpleAggregate = function (resource, query, yFunc, yColumn, params, columns) {
        if (columns === void 0) { columns = []; }
        return this.getSimple(resource, query, params, undefined, columns, false).pipe(map(function (response) { return aggregateGetResponse(response, yFunc, yColumn); }));
    };
    JetBridgeResourceController.prototype.getSqlAggregate = function (resource, query, yFunc, yColumn, parameters, params, columns) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        return this.checkApiInfo(resource).pipe(switchMap(function () {
            var apiInfo = resource ? resource.apiInfo : undefined;
            var supported = apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.3', jetDjango: '1.1.8' });
            var aggregate = yFunc
                ? {
                    func: yFunc,
                    column: yColumn
                }
                : undefined;
            return _this.getSql(resource, query, parameters, params, undefined, columns, false, aggregate).pipe(map(function (response) { return aggregateGetResponse(response, yFunc, yColumn, !supported); }));
        }));
    };
    JetBridgeResourceController.prototype.modelAggregate = function (resource, modelDescription, yFunc, yColumn, params) {
        if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.simpleQuery && isSet(yFunc)) {
            return this.aggregateSimple(resource, modelDescription.model, yFunc, yColumn, params);
            // TODO if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.sqlQuery)
        }
        else if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.simpleQuery && !isSet(yFunc)) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getSimpleAggregate(resource, modelDescription.getQuery, yFunc, yColumn, params, columns);
        }
        else if (modelDescription.getQuery && modelDescription.getQuery.simpleQuery) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getSimpleAggregate(resource, modelDescription.getQuery, yFunc, yColumn, params, columns);
        }
        else if (modelDescription.getQuery && modelDescription.getQuery.sqlQuery) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getSqlAggregate(resource, modelDescription.getQuery, yFunc, yColumn, modelDescription.getParameters, params, columns);
        }
        else {
            return of(undefined);
        }
    };
    JetBridgeResourceController.prototype.groupSimple = function (resource, model, xColumns, yFunc, yColumn, params) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.actionUrlForProjectResource(model, 'group', resource);
            var headers = _this.getHeaders(resource);
            var httpParams = new HttpParams({
                fromObject: __assign({}, (params || {}), (isSet(yFunc) ? { _y_func: yFunc } : {}), (isSet(yColumn) ? { _y_column: yColumn } : {}), { tz: getTimezoneOffset() }),
                encoder: _this.parameterCodec
            });
            xColumns.forEach(function (item) {
                httpParams = httpParams.append('_x_column', item.xColumn);
                httpParams = httpParams.append('_x_lookup', item.xLookup || '');
            });
            return _this.http.get(url, { headers: headers, params: httpParams, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) { return result.map(function (item) { return new DataGroup().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.getSimpleGroup = function (resource, query, xColumns, yFunc, yColumn, params, columns) {
        if (columns === void 0) { columns = []; }
        return this.getSimple(resource, query, params, undefined, columns, false).pipe(map(function (response) { return groupGetResponse(response, xColumns, yFunc, yColumn); }));
    };
    JetBridgeResourceController.prototype.getSqlGroup = function (resource, query, xColumns, yFunc, yColumn, parameters, params, columns) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        return this.checkApiInfo(resource).pipe(switchMap(function () {
            var apiInfo = resource ? resource.apiInfo : undefined;
            var supported = apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.3', jetDjango: '1.1.8' });
            var group = yFunc
                ? {
                    xColumns: xColumns,
                    yColumn: yColumn,
                    yFunc: yFunc
                }
                : undefined;
            return _this.getSql(resource, query, parameters, params, undefined, columns, false, undefined, group).pipe(map(function (response) { return groupGetResponse(response, xColumns, yFunc, yColumn, !supported); }));
        }));
    };
    JetBridgeResourceController.prototype.modelGroup = function (resource, modelDescription, xColumns, yFunc, yColumn, params) {
        if (modelDescription.groupQuery && modelDescription.groupQuery.simpleQuery && isSet(yFunc)) {
            return this.groupSimple(resource, modelDescription.model, xColumns, yFunc, yColumn, params);
            // TODO if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.sqlQuery)
        }
        else if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.simpleQuery && !isSet(yFunc)) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getSimpleGroup(resource, modelDescription.getQuery, xColumns, yFunc, yColumn, params, columns);
        }
        else if (modelDescription.getQuery && modelDescription.getQuery.simpleQuery) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getSimpleGroup(resource, modelDescription.getQuery, xColumns, yFunc, yColumn, params, columns);
        }
        else if (modelDescription.getQuery && modelDescription.getQuery.sqlQuery) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getSqlGroup(resource, modelDescription.getQuery, xColumns, yFunc, yColumn, modelDescription.getParameters, params, columns);
        }
        else {
            return of(undefined);
        }
    };
    JetBridgeResourceController.prototype.modelGetSiblings = function (resource, modelDescription, id, params, body) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.detailActionUrlForProjectResource(modelDescription.model, id, 'get_siblings', resource);
            var headers = _this.getHeaders(resource);
            params = params || {};
            if (body) {
                headers = headers.set('X-HTTP-Method-Override', HttpMethod.GET);
                return _this.http
                    .request('post', url, {
                    headers: headers,
                    params: params,
                    body: body,
                    observe: 'response'
                })
                    .pipe(_this.apiService.processApiResponse(), map(function (result) { return result || {}; }), map(function (result) {
                    var siblings = {};
                    if (result['prev']) {
                        siblings['prev'] = _this.createModel().deserialize(modelDescription.model, result['prev']);
                        siblings['prev'].setUp(modelDescription);
                        siblings['prev'].deserializeAttributes(modelDescription.dbFields);
                    }
                    if (result['next']) {
                        siblings['next'] = _this.createModel().deserialize(modelDescription.model, result['next']);
                        siblings['next'].setUp(modelDescription);
                        siblings['next'].deserializeAttributes(modelDescription.dbFields);
                    }
                    return siblings;
                }));
            }
            else {
                return _this.http.get(url, { headers: headers, params: params, observe: 'response' }).pipe(_this.apiService.processApiResponse(), map(function (result) { return result || {}; }), map(function (result) {
                    var siblings = {};
                    if (result['prev']) {
                        siblings['prev'] = _this.createModel().deserialize(modelDescription.model, result['prev']);
                        siblings['prev'].setUp(modelDescription);
                        siblings['prev'].deserializeAttributes(modelDescription.dbFields);
                    }
                    if (result['next']) {
                        siblings['next'] = _this.createModel().deserialize(modelDescription.model, result['next']);
                        siblings['next'].setUp(modelDescription);
                        siblings['next'].deserializeAttributes(modelDescription.dbFields);
                    }
                    return siblings;
                }));
            }
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.prepareSqlQueryData = function (resource, query, options) {
        if (options === void 0) { options = {}; }
        var newQuery = options.version &&
            options.version >= 2 &&
            resource.apiInfo &&
            resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.0', jetDjango: '1.1.5' }) &&
            String(query).indexOf('-- @JET_QUERY_VERSION=1') == -1;
        if (newQuery) {
            var sql = this.sqlQueryService.applyTokensSql(query, options.tokens || {}, true);
            var multipleGroupsSupported = resource.apiInfo.isCompatibleJetBridge({
                jetBridge: '1.0.9',
                jetDjango: '1.2.4'
            });
            if (!sql) {
                return {};
            }
            return __assign({ query: sql.query, offset: options.offset, limit: options.limit, order_by: options.orderBy, count: options.count, columns: options.columns, filters: options.filters, aggregate: options.aggregate }, (multipleGroupsSupported
                ? {
                    groups: options.groups,
                    timezone: getTimezoneOffset()
                }
                : {
                    group: options.groups && options.groups.xColumns.length
                        ? {
                            xColumn: options.groups.xColumns[0].xColumn,
                            xLookup: options.groups.xColumns[0].xLookup,
                            yFunc: options.groups.yFunc,
                            yColumn: options.groups.yColumn
                        }
                        : undefined,
                    timezone: getTimezoneOffset()
                }), (isSet(resource.params['schema']) ? { schema: resource.params['schema'] } : {}), { params_obj: sql.params, v: options.version });
        }
        else {
            query = this.queryService.applyTokens(query, options.tokens, true);
            var sql = interpolateSqlContext(query, options.tokens || {});
            if (!sql) {
                return {};
            }
            return {
                query: sql.str,
                params: sql.params.length ? sql.params.join(',') : undefined
            };
        }
    };
    JetBridgeResourceController.prototype.sql = function (resource, query, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () { return _this.checkApiInfo(resource); }), switchMap(function () {
            var url = _this.apiService.methodURLForProjectResource('sql/', resource);
            var headers = _this.getHeaders(resource);
            var data = _this.prepareSqlQueryData(resource, query, options);
            return _this.http.post(url, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            if (result instanceof Array) {
                return new ModelResponse.SqlResponse().deserialize({
                    data: result
                });
            }
            return new ModelResponse.SqlResponse().deserialize(result);
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.sqls = function (resource, queries) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () { return _this.checkApiInfo(resource); }), switchMap(function () {
            var url = _this.apiService.methodURLForProjectResource('sql/', resource);
            var headers = _this.getHeaders(resource);
            var data = {
                queries: queries.map(function (item) { return _this.prepareSqlQueryData(resource, item.query, item.options); })
            };
            return _this.http.post(url, data, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            return result.map(function (item) {
                if (item instanceof Array) {
                    return new ModelResponse.SqlResponse().deserialize({
                        data: item
                    });
                }
                return new ModelResponse.SqlResponse().deserialize(item);
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.uploadFile = function (resource, storage, query, file, path, fileName) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURLForProjectResource('file_upload/', resource);
            var headers = _this.getHeaders(resource);
            var data = new FormData();
            data.append('file', file);
            data.append('path', path);
            if (isSet(fileName)) {
                data.append('filename', fileName);
            }
            else if (isSet(file.name)) {
                data.append('filename', file.name);
            }
            return _this.http.post(url, data, { headers: headers, observe: 'events', reportProgress: true });
        }), switchMap(function (event) {
            if (event.type == HttpEventType.Response) {
                return of(event).pipe(_this.apiService.processApiResponse(), map(function (result) {
                    return {
                        result: {
                            uploadedPath: result['uploaded_path'],
                            uploadedUrl: result['uploaded_url']
                        },
                        state: {
                            downloadProgress: 1,
                            uploadProgress: 1
                        }
                    };
                }));
            }
            else if (event.type == HttpEventType.UploadProgress) {
                return of({
                    state: {
                        uploadProgress: event.loaded / event.total,
                        downloadProgress: 0,
                        uploadLoaded: event.loaded,
                        uploadTotal: event.total
                    }
                });
            }
            else if (event.type == HttpEventType.DownloadProgress) {
                return of({
                    state: {
                        uploadProgress: 1,
                        downloadProgress: event.loaded / event.total,
                        downloadLoaded: event.loaded,
                        downloadTotal: event.total
                    }
                });
            }
            else {
                return EMPTY;
            }
        }), this.apiService.catchApiError());
    };
    JetBridgeResourceController.prototype.fileUrl = function (resource, value) {
        if (isAbsoluteUrl(value)) {
            return value;
        }
        else if (resource && resource.mediaUrlTemplate) {
            return resource.mediaUrl(value);
        }
        else if (resource.params['url']) {
            var base = resource.params['url'].replace(/(api|jet_api)\//, '');
            return base + "media/" + value;
        }
        else {
            return value;
        }
    };
    JetBridgeResourceController.prototype.actionExecuteSql = function (resource, query, parameters, params, rawErrors) {
        if (parameters === void 0) { parameters = []; }
        if (!query || !query.sqlQuery) {
            return of(undefined);
        }
        var userQuery = isResourceQueryCustom(resource, query.queryType);
        var tokens = this.queryTokensService.mergeTokens(this.queryTokensService.generalTokens(), this.queryTokensService.actionExecuteTokens(params, parameters, userQuery));
        return this.sql(resource, query.sqlQuery.query, { tokens: tokens, version: query.sqlQuery.version }).pipe(map(function (result) {
            return {
                json: result.toObject()
            };
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    JetBridgeResourceController.prototype.reload = function (resource) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURLForProjectResource('reload/', resource);
            var headers = _this.getHeaders(resource);
            return _this.http.post(url, {}, { headers: headers, observe: 'response' });
        }), this.apiService.processApiResponse(), map(function (result) {
            return result['result'];
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return JetBridgeResourceController;
}(ResourceController));
export { JetBridgeResourceController };
