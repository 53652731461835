/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../customize-components/components/custom-page-popup/custom-page-popup.component.ngfactory";
import * as i2 from "../../../customize/data/view-context-element";
import * as i3 from "../../../customize/data/view-context";
import * as i4 from "../../../customize-components/components/custom-page-popup/custom-page-popup.component";
import * as i5 from "../../../customize/services/customize/customize.service";
import * as i6 from "../../../customize-bar/services/customize-bar/customize-bar.service";
import * as i7 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i8 from "../../../../common/popups/services/popup/popup.service";
import * as i9 from "../../../../core/services/session-storage/session.storage";
import * as i10 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i11 from "../page-template-preview-page/page-template-preview-page.component.ngfactory";
import * as i12 from "../page-template-preview-page/page-template-preview-page.component";
import * as i13 from "@angular/common";
import * as i14 from "../../../projects/stores/current-environment.store";
import * as i15 from "./page-template-preview-start-page.component";
var styles_PageTemplatePreviewStartPageComponent = [];
var RenderType_PageTemplatePreviewStartPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatePreviewStartPageComponent, data: {} });
export { RenderType_PageTemplatePreviewStartPageComponent as RenderType_PageTemplatePreviewStartPageComponent };
function View_PageTemplatePreviewStartPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "choose-template__detail-bottom-gradient"]], [[2, "choose-template__detail-bottom-gradient_visible", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_PageTemplatePreviewStartPageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "choose-template__detail-overlay"]], [[2, "choose-template__detail-overlay_visible", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "choose-template__detail-popup"]], [[2, "choose-template__detail-popup_visible", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "app-custom-page-popup", [], [[1, "data-popup-id", 0], [1, "data-popup-name", 0]], null, null, i1.View_CustomPagePopupComponent_0, i1.RenderType_CustomPagePopupComponent)), i0.ɵprd(131584, null, i2.ViewContextElement, i2.ViewContextElement, [i3.ViewContext]), i0.ɵdid(5, 4964352, null, 0, i4.CustomPagePopupComponent, [i5.CustomizeService, i6.CustomizeBarService, [2, i7.CustomizeBarContext], i2.ViewContextElement, i8.PopupService, i9.SessionStorage, i10.UniversalAnalyticsService, i0.ChangeDetectorRef], { popup: [0, "popup"], context: [1, "context"], visible: [2, "visible"], closeEnabled: [3, "closeEnabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.popup; var currVal_5 = _co.context; var currVal_6 = true; var currVal_7 = false; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupFocus; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.popupFocus; _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 5).elementId; var currVal_3 = i0.ɵnov(_v, 5).elementName; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_PageTemplatePreviewStartPageComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-template-preview-page", [["class", "choose-template__detail-browser"]], [[2, "choose-template__detail-browser_focus", null]], null, null, i11.View_PageTemplatePreviewPageComponent_0, i11.RenderType_PageTemplatePreviewPageComponent)), i0.ɵprd(131584, null, i2.ViewContextElement, i2.ViewContextElement, [i3.ViewContext]), i0.ɵdid(2, 4833280, null, 0, i12.PageTemplatePreviewPageComponent, [i3.ViewContext, i2.ViewContextElement], { page: [0, "page"], context: [1, "context"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatePreviewStartPageComponent_1)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatePreviewStartPageComponent_2)), i0.ɵdid(6, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.page; var currVal_2 = _co.context; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.fade; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.popup; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.startPageFocus; _ck(_v, 0, 0, currVal_0); }); }
export function View_PageTemplatePreviewStartPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-template-preview-start-page", [], null, null, null, View_PageTemplatePreviewStartPageComponent_0, RenderType_PageTemplatePreviewStartPageComponent)), i0.ɵprd(131584, null, i3.ViewContext, i3.ViewContext, [i14.CurrentEnvironmentStore]), i0.ɵdid(2, 638976, null, 0, i15.PageTemplatePreviewStartPageComponent, [i3.ViewContext], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PageTemplatePreviewStartPageComponentNgFactory = i0.ɵccf("app-page-template-preview-start-page", i15.PageTemplatePreviewStartPageComponent, View_PageTemplatePreviewStartPageComponent_Host_0, { page: "page", fade: "fade", startPageFocus: "startPageFocus", popupFocus: "popupFocus" }, {}, []);
export { PageTemplatePreviewStartPageComponentNgFactory as PageTemplatePreviewStartPageComponentNgFactory };
