var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionDescription, ActionType, ClosePopupAction, ViewSettingsAction } from '@modules/actions';
import { FieldElementItem, FormElementItem, FormSubmitElementItem, generateElementName, modelFieldToDisplayField, VALUE_OUTPUT } from '@modules/customize';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { fieldsEditItemFromParameterField } from '@modules/field-components';
import { Input, InputValueType } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelFieldType } from '@modules/models';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { isSet } from '@shared';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
var UpdateFormGenerator = /** @class */ (function () {
    function UpdateFormGenerator(generatorUtils) {
        this.generatorUtils = generatorUtils;
    }
    UpdateFormGenerator.prototype.getUpdateParameters = function (modelDescription) {
        return modelDescription.updateParametersOrDefaults.filter(function (item) { return item.name != modelDescription.primaryKeyField; });
    };
    UpdateFormGenerator.prototype.getElement = function (resource, modelDescription, options) {
        var _this = this;
        var formElement = new FormElementItem();
        if (options.idGenerator && isSet(options.idUniqueName)) {
            formElement.uid = options.idGenerator ? options.idGenerator.elementId(options.idUniqueName) : undefined;
        }
        else {
            formElement.generateUid();
        }
        formElement.generated = true;
        var getQuery = new ModelDescriptionQuery();
        getQuery.queryType = QueryType.Simple;
        getQuery.simpleQuery = new getQuery.simpleQueryClass();
        getQuery.simpleQuery.model = modelDescription.model;
        var pkInput = new Input();
        pkInput.path = [modelDescription.primaryKeyField];
        pkInput.valueType = InputValueType.Context;
        pkInput.contextValue = options.pkContextValue;
        var fieldElements = this.getUpdateParameters(modelDescription).map(function (item) {
            var element = new FieldElementItem();
            element.settings = { field: item.field };
            _this.generatorUtils.applyElementTemplate(element, options.templates);
            if (options.idGenerator) {
                element.uid = options.idGenerator
                    ? options.idGenerator.elementId(options.idUniqueName + "_" + item.name)
                    : undefined;
            }
            else {
                element.generateUid();
            }
            element.settings = __assign({}, fieldsEditItemFromParameterField(item), { verboseName: item.verboseName || item.name, editable: true });
            element.updateFormField();
            element.name = generateElementName(element, {});
            var input = new Input();
            input.path = ['value'];
            input.valueType = InputValueType.Context;
            input.contextValue = ['elements', formElement.uid, ITEM_OUTPUT, item.name];
            element.settings.valueInput = input;
            return element;
        });
        if (options.fields) {
            fieldElements = fieldElements
                .filter(function (item) { return options.fields.includes(item.settings.name); })
                .sort(function (lhs, rhs) { return _this.generatorUtils.fieldsSort(options.fields, lhs.settings.name, rhs.settings.name); });
        }
        formElement.getDataSource = new ModelDescriptionDataSource();
        formElement.getDataSource.type = DataSourceType.Query;
        formElement.getDataSource.queryResource = resource.uniqueName;
        formElement.getDataSource.query = getQuery;
        formElement.getDataSource.queryInputs = [pkInput];
        formElement.getDataSource.columns = modelDescription.fields
            .filter(function (item) { return item.type == ModelFieldType.Db; })
            .map(function (item) { return modelFieldToDisplayField(item); });
        formElement.children = fieldElements.slice();
        var autoAction = modelDescription.autoActions().find(function (item) { return item.name == 'update'; });
        if (autoAction) {
            var submitElement = new FormSubmitElementItem();
            if (options.idGenerator) {
                submitElement.uid = options.idGenerator
                    ? options.idGenerator.elementId(options.idUniqueName + "___submit__")
                    : undefined;
            }
            else {
                submitElement.generateUid();
            }
            submitElement.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);
            formElement.children.push(submitElement);
            var action = new ViewSettingsAction();
            action.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);
            action.sharedActionDescription = [resource.uniqueName, autoAction.uniqueName].join('.');
            action.inputs = modelDescription.updateParametersOrDefaults.map(function (item) {
                var input = new Input();
                input.path = [item.name];
                input.valueType = InputValueType.Context;
                if (item.name == modelDescription.primaryKeyField) {
                    input.contextValue = options.pkContextValue;
                }
                else {
                    if (!options.fields || options.fields.includes(item.name)) {
                        var fieldElement = fieldElements.find(function (element) { return element.settings.name == item.name; });
                        if (fieldElement) {
                            input.contextValue = ['elements', fieldElement.uid, VALUE_OUTPUT];
                        }
                    }
                    else {
                        input.contextValue = [ITEM_OUTPUT, item.name];
                    }
                }
                return input;
            });
            if (options.closePopupOnSuccess) {
                var closePopupAction = new ClosePopupAction();
                closePopupAction.popup = options.closePopupOnSuccess;
                var successActionDescription = new ActionDescription();
                successActionDescription.type = ActionType.ClosePopup;
                successActionDescription.closePopupAction = closePopupAction;
                var successAction = new ViewSettingsAction();
                successAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Close Modal');
                successAction.actionDescription = successActionDescription;
                action.onSuccessActions = [successAction];
            }
            formElement.submitAction = action;
        }
        return formElement;
    };
    return UpdateFormGenerator;
}());
export { UpdateFormGenerator };
