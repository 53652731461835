import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import toPairs from 'lodash/toPairs';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { RawListViewSettingsColumn } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import {
  EditableField,
  getFieldDescriptionByType,
  Input,
  InputValueType,
  lookupMatchers,
  ParameterField
} from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { ListModelDescriptionQuery } from '@modules/queries';
import { forceObservable, isSet } from '@shared';

import { queryHasFrontendAutoParameters, queryHasResourceAutoParameters } from '../utils/auto-parameters';

export interface InputFieldProviderItem {
  label: string;
  icon?: string;
  warning?: string;
  field?: EditableField;
  children?: InputFieldProviderItem[];
  defaultValueType?: InputValueType;
}

export function flattenInputFieldProviderItems(items: InputFieldProviderItem[]): InputFieldProviderItem[] {
  return items.reduce((prev, item) => {
    if (item.field) {
      prev.push(item);
    }

    if (item.children) {
      prev.push(...flatten(item.children));
    }

    return prev;
  }, []);
}

export class InputFieldProvider {
  lookupsEnabled = false;

  private _items = new BehaviorSubject<InputFieldProviderItem[]>(undefined);
  private subscription: Subscription;

  getItems(defaultValue = []): InputFieldProviderItem[] {
    const value = this._items.value;

    if (value === undefined) {
      return defaultValue;
    }

    return value;
  }

  getItems$(defaultValue = []): Observable<InputFieldProviderItem[]> {
    return this._items.asObservable().pipe(
      map(value => {
        if (value === undefined) {
          return defaultValue;
        }

        return value;
      })
    );
  }

  getItemFields(value: InputFieldProviderItem[]): EditableField[] {
    return flattenInputFieldProviderItems(value)
      .filter(item => item.field)
      .map(item => item.field);
  }

  getFields(defaultValue = []): EditableField[] {
    return this.getItemFields(this.getItems(defaultValue));
  }

  getFields$(defaultValue = []): Observable<EditableField[]> {
    return this.getItems$(defaultValue).pipe(
      map(value => this.getItemFields(value)),
      distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs))
    );
  }

  setProvider(provider: InputFieldProviderItem[] | Observable<InputFieldProviderItem[]>, lookupsEnabled = false) {
    this.clearProvider();
    this.lookupsEnabled = lookupsEnabled;
    this.subscription = forceObservable(provider).subscribe(value => {
      this._items.next(value);
    });
  }

  clearProvider() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }
}

export function fieldsToProviderItems(fields: EditableField[]): InputFieldProviderItem[] {
  return fields.map(item => {
    return {
      label: item.verboseName || item.name,
      field: {
        ...item
      }
    };
  });
}

export function parametersToProviderItemsFlat(parameters: ParameterField[]): InputFieldProviderItem[] {
  return parameters.map(item => {
    return {
      label: item.verboseName || item.name,
      field: {
        ...item
      }
    };
  });
}

export function parametersToProviderItems(parameters: ParameterField[]): InputFieldProviderItem[] {
  const noGroupKey = '__no-group__';

  return toPairs(
    parameters.reduce((acc, item) => {
      const group = item.group || noGroupKey;
      const groupIcon = item.groupIcon;

      if (!acc[group]) {
        acc[group] = {
          icon: groupIcon,
          items: []
        };
      }

      const fieldDescription = getFieldDescriptionByType(item.field);

      acc[group].items.push({
        label: item.verboseName || item.name,
        icon: item.icon || fieldDescription.icon,
        field: {
          ...item
        }
      });

      return acc;
    }, {})
  ).reduce((acc, pairs) => {
    const group = pairs[0];
    const groupData = pairs[1] as {
      icon?: string;
      items: InputFieldProvider[];
    };

    if (group == noGroupKey) {
      acc.push(...groupData.items);
    } else {
      acc.push({
        label: group,
        icon: groupData.icon,
        children: groupData.items
      });
    }

    return acc;
  }, []);
}

export function autoParametersFromModelGet(
  resource: Resource,
  modelDescription: ModelDescription,
  getQuery?: ListModelDescriptionQuery,
  columns: RawListViewSettingsColumn[] = [],
  type?: DataSourceType
): ParameterField[] {
  const result: ParameterField[] = [];

  if (queryHasResourceAutoParameters(resource, getQuery, modelDescription)) {
    result.push(
      ...modelDescription.dbFields.reduce((fieldsResult, item) => {
        const fieldDescription = getFieldDescriptionByType(item.field);

        return fieldDescription.lookups.reduce((acc, lookup) => {
          const params = {
            ...fieldDescription.defaultParams,
            ...pickBy(item.params, (v, k) => lookup.fieldParams && lookup.fieldParams.includes(k)),
            ...fieldDescription.forceParams
          };

          [false, true].forEach(exclude => {
            const instance = new ParameterField();
            const prefix = exclude ? 'exclude' : '';
            const name = [prefix, item.name, lookup.type.lookup].filter((str, i) => isSet(str) || i == 1).join('__');
            const verboseName = [prefix, item.verboseName || item.name, lookup.type.verboseName]
              .filter((str, i) => isSet(str) || i == 1)
              .join(' ');

            instance.group = item.verboseName || item.name;
            instance.groupIcon = fieldDescription.icon;
            // instance.name = serializeFieldParamName(item.name, lookup.type.lookup);
            instance.name = name;
            instance.verboseName = verboseName;
            instance.icon = lookup.type ? lookup.type.icon : undefined;
            instance.field = lookup.field;
            instance.required = false;
            instance.params = params;
            instance.updateFieldDescription();

            acc.push(instance);
          });

          return acc;
        }, fieldsResult);
      }, [])
    );
  } else if (
    type == DataSourceType.Input ||
    type == DataSourceType.Workflow ||
    queryHasFrontendAutoParameters(resource, getQuery, modelDescription)
  ) {
    result.push(
      ...columns.reduce((acc, column) => {
        const fieldDescription = getFieldDescriptionByType(column.field);

        fieldDescription.lookups.forEach(lookup => {
          const params = {
            ...fieldDescription.defaultParams,
            ...pickBy(column.params, (v, k) => lookup.fieldParams && lookup.fieldParams.includes(k)),
            ...fieldDescription.forceParams
          };
          const lookupMatcher = lookupMatchers.find(i => i.field == column.field && i.lookup === lookup.type);

          if (!lookupMatcher) {
            return;
          }

          [false, true].forEach(exclude => {
            const instance = new ParameterField();
            const prefix = exclude ? 'exclude' : '';
            const name = [prefix, column.name, lookup.type.lookup].filter(item => !!item).join('__');
            const verboseName = [prefix, column.verboseName || column.name, lookup.type.verboseName]
              .filter(item => !!item)
              .join(' ');

            instance.group = column.verboseName || column.name;
            instance.groupIcon = fieldDescription.icon;
            // instance.name = serializeFieldParamName(column.name, lookup.type.lookup, exclude);
            instance.name = name;
            instance.verboseName = verboseName;
            instance.icon = lookup.type ? lookup.type.icon : undefined;
            instance.field = lookup.field;
            instance.required = false;
            instance.params = params;
            instance.updateFieldDescription();

            acc.push(instance);
          });
        });

        return acc;
      }, [])
    );
  }

  return result;
}

export function autoInputsFromModelGet(
  resource: Resource,
  modelDescription: ModelDescription,
  getQuery?: ListModelDescriptionQuery,
  columns: RawListViewSettingsColumn[] = [],
  type?: DataSourceType
): Input[] {
  const result = [];

  if (queryHasResourceAutoParameters(resource, getQuery, modelDescription)) {
    result.push(
      ...modelDescription.dbFields.reduce((fieldsResult, item) => {
        const fieldDescription = getFieldDescriptionByType(item.field);

        return fieldDescription.lookups.reduce((lookupsResult, lookup) => {
          const instance = new Input();

          instance.path = [item.name];
          instance.lookup = lookup.type.lookup;
          instance.valueType = InputValueType.Filter;
          instance.filterField = item.name;
          instance.filterLookup = lookup.type.lookup;

          lookupsResult.push(instance);

          return lookupsResult;
        }, fieldsResult);
      }, [])
    );
  } else if (
    type == DataSourceType.Input ||
    type == DataSourceType.Workflow ||
    queryHasFrontendAutoParameters(resource, getQuery, modelDescription)
  ) {
    result.push(
      ...columns.reduce((acc, column) => {
        const fieldDescription = getFieldDescriptionByType(column.field);
        fieldDescription.lookups.forEach(lookup => {
          const lookupMatcher = lookupMatchers.find(i => i.field == column.field && i.lookup === lookup.type);

          if (!lookupMatcher) {
            return;
          }

          [false, true].forEach(exclude => {
            const input = new Input();

            input.path = [column.name];
            input.lookup = lookup.type.lookup;
            input.exclude = exclude;
            input.valueType = InputValueType.Filter;
            input.filterField = column.name;
            input.filterLookup = lookup.type.lookup;

            acc.push(input);
          });
        });

        return acc;
      }, [])
    );
  }

  return result;
}

// export function parametersFromModelGet(
//   resource: Resource,
//   modelDescription: ModelDescription,
//   parameters: ParameterField[],
//   modelDescriptionQuery?: ListModelDescriptionQuery,
//   columns: RawListViewSettingsColumn[] = []
// ): ParameterField[] {
//   const result: ParameterField[] = [];
//
//   if (modelDescription) {
//     result.push(...modelDescription.getParameters);
//   } else if (parameters) {
//     result.push(...parameters.filter(item => item.name));
//   }
//
//   if (
//     modelDescriptionQuery &&
//     ((modelDescriptionQuery.simpleQuery &&
//       modelDescription &&
//       modelDescription.getQuery &&
//       modelDescription.getQuery.frontendFiltering) ||
//       (!modelDescriptionQuery.simpleQuery && modelDescriptionQuery.frontendFiltering))
//   ) {
//     result.push(
//       ...columns.reduce((acc, column) => {
//         const fieldDescription = getFieldDescriptionByType(column.field);
//         fieldDescription.lookups.forEach(lookup => {
//           const lookupMatcher = lookupMatchers.find(i => i.field == column.field && i.lookup === lookup.type);
//
//           if (!lookupMatcher) {
//             return;
//           }
//
//           ['', 'exclude'].forEach(prefix => {
//             const name = [prefix, column.name, lookup.type.lookup].filter(item => !!item).join('__');
//
//             if (result.find(item => item.name == name)) {
//               return;
//             }
//
//             const parameter = new ParameterField();
//             const verboseName = [prefix, column.verboseName || column.name, lookup.type.verboseName]
//               .filter(item => !!item)
//               .join(' ');
//
//             parameter.group = column.verboseName || column.name;
//             parameter.name = name;
//             parameter.verboseName = verboseName;
//             parameter.field = lookup.field;
//             parameter.required = false;
//             parameter.params = lookup.extraParams || {};
//             parameter.updateFieldDescription();
//
//             acc.push(parameter);
//           });
//         });
//
//         return acc;
//       }, [])
//     );
//   }
//
//   return result;
// }

export function inputFieldProviderItemsFromModelGet(
  resource: Resource,
  modelDescription: ModelDescription,
  modelDescriptionQuery?: ListModelDescriptionQuery,
  columns: RawListViewSettingsColumn[] = [],
  type?: DataSourceType
): InputFieldProviderItem[] {
  return parametersToProviderItems([
    ...(modelDescription ? modelDescription.getParameters : []),
    ...autoParametersFromModelGet(resource, modelDescription, modelDescriptionQuery, columns, type)
  ]);
}

export function inputFieldProviderItemsFromModelGetDetail(
  resource: Resource,
  modelDescription: ModelDescription,
  parameters: ParameterField[]
): InputFieldProviderItem[] {
  const result = [];

  if (parameters) {
    result.push(...parametersToProviderItems(parameters.filter(item => item.name)));
  }

  if (modelDescription) {
    const modelParameters = modelDescription.getDetailParametersOrDefaults;
    result.push(...parametersToProviderItems(modelParameters));
  }

  return result;
}

export function listModelDescriptionInputFieldProvider(
  dataSourceType: DataSourceType,
  resource: Resource,
  modelDescription: ModelDescription,
  parameters: ParameterField[],
  getQuery: ListModelDescriptionQuery,
  columns: RawListViewSettingsColumn[]
): InputFieldProviderItem[] {
  return [
    ...parametersToProviderItems(parameters),
    ...inputFieldProviderItemsFromModelGet(resource, modelDescription, getQuery, columns, dataSourceType)
  ];
}
