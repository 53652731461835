import { ChangeDetectorRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import defaults from 'lodash/defaults';
import isArray from 'lodash/isArray';
import range from 'lodash/range';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, createFormFieldFactory, FieldType, InputValueType, ParameterField } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
import { TypedChanges } from '@shared';

import { BaseActionExecuteForm } from './base-action-execute.form';

export interface ActionExecuteFinishedEvent {
  processedCount: number;
  successCount: number;
  failedCount: number;
  totalCount: number;
  result?: any;
}

export abstract class BaseActionExecuteComponent implements OnInit, OnDestroy, OnChanges {
  @Input() action: ActionItem;
  @Input() actionDescription: ActionDescription;
  @Input() modelDescription: ModelDescription;
  @Input() params = {};
  @Input() options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    saveResultTo?: string;
    showSuccess?: boolean;
    showError?: boolean;
  } = {};
  @Input() submitLabel = 'Execute';
  @Input() fill = false;
  @Input() theme = false;
  @Input() primaryKeyAutoLookup = true;
  @Input() analyticsSource: string;
  @Output() finished = new EventEmitter<ActionExecuteFinishedEvent>();
  @Output() cancelled = new EventEmitter<void>();

  verboseName: string;
  verboseNameSubscription: Subscription;
  paramValues: Object = {};
  actionParams: ParameterField[] = [];

  executeStarted = false;
  executeProcessedCount = 0;
  executeSucceededCount = 0;
  executeTotalCount = 0;

  createField = createFormFieldFactory();

  constructor(
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected actionService: ActionService,
    protected actionControllerService: ActionControllerService,
    public form: BaseActionExecuteForm,
    protected notificationService: NotificationService,
    protected injector: Injector,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.init();
    this.initVerboseName();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<BaseActionExecuteComponent>): void {
    if (
      (changes.action && !changes.action.firstChange) ||
      (changes.actionDescription && !changes.actionDescription.firstChange)
    ) {
      this.init();
      this.initVerboseName();
    }
  }

  get currentOptions() {
    return defaults(this.options, { showSuccess: true, showError: true });
  }

  init() {
    if (!this.action || !this.actionDescription) {
      this.form.deinit();
      return;
    }

    const paramValues = this.getParamValues(this.actionDescription, this.params);

    this.paramValues = paramValues;
    this.actionParams = this.actionDescription.actionParams
      .filter(parameter => {
        if (parameter.required && !paramValues.hasOwnProperty(parameter.name)) {
          return true;
        }

        return this.action.inputs.find(item => item.isName(parameter.name) && item.valueType == InputValueType.Prompt);
      })
      .map(item => {
        const primaryKeyLookup =
          this.modelDescription &&
          this.modelDescription.primaryKeyField &&
          item.name == this.modelDescription.primaryKeyField &&
          this.modelDescription.getQuery &&
          this.modelDescription.field(item.name);

        if (!this.fill && !primaryKeyLookup) {
          return item;
        }

        const field = new ParameterField().deserialize(item.serialize());

        if (primaryKeyLookup && this.primaryKeyAutoLookup) {
          field.field = FieldType.RelatedModel;
          field.params = {
            ...field.params,
            related_model: { model: this.modelDescription.modelId },
            open_button: false,
            create_button: false
          };
        }

        if (this.fill) {
          field.params = {
            ...field.params,
            classes: ['select_fill', 'input_fill'],
            theme: this.theme
          };
        }

        field.updateFieldDescription();

        return field;
      });

    // TODO: Add bulk actions
    this.executeTotalCount = values(this.paramValues).length
      ? Math.max(...values(this.paramValues).map(item => (isArray(item) ? item.length : 1)))
      : 1;

    this.form.init(this.actionParams, { context: this.options.context, contextElement: this.options.contextElement });
  }

  initVerboseName() {
    if (this.verboseNameSubscription) {
      this.verboseNameSubscription.unsubscribe();
      this.verboseNameSubscription = undefined;
    }

    if (!this.action || !this.action.verboseNameInput) {
      this.verboseName = undefined;
      this.cd.markForCheck();
      return;
    }

    this.verboseNameSubscription = applyParamInput$<string>(this.action.verboseNameInput, {
      context: this.options.context,
      contextElement: this.options.contextElement,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.verboseName = value;
        this.cd.markForCheck();
      });
  }

  getParamValues(action: ActionDescription, params: Object): Object {
    return action.actionParams.reduce((acc, item) => {
      const paramName = item.name.replace(/__/g, '%5F%5F');
      if (params.hasOwnProperty(paramName)) {
        acc[item.name] = params[paramName];
      }
      return acc;
    }, {});
  }

  execute() {
    if (this.form.form.invalid || this.executeStarted || this.executeProcessedCount == this.executeTotalCount) {
      return;
    }

    const params = this.form.submit();

    if (this.action.approve) {
      const paramCall = {
        ...this.paramValues,
        ...params
      };

      this.actionService
        .requestApproval(this.action, paramCall)
        .pipe(untilDestroyed(this))
        .subscribe(
          task => {
            if (!task) {
              return;
            }

            this.onSubmitCompleted(true, undefined);
          },
          error => {
            if (error instanceof ServerRequestError && error.errors.length) {
              this.notificationService.error('Error', error.errors[0]);
            } else {
              this.notificationService.error('Error', error);
            }
          }
        );
    } else {
      let paramCalls: Object[];

      if (this.actionDescription.type == ActionType.Export) {
        const resource =
          this.actionDescription.exportAction &&
          this.actionDescription.exportAction.dataSource &&
          this.actionDescription.exportAction.dataSource.type == DataSourceType.Query &&
          this.actionDescription.exportAction.dataSource.queryResource
            ? this.currentEnvironmentStore.resources.find(
                item => item.uniqueName == this.actionDescription.exportAction.dataSource.queryResource
              )
            : undefined;

        paramCalls = [
          {
            ...toPairs(this.paramValues).reduce((acc, [k, v]) => {
              if (isArray(v)) {
                acc[`${k}__in`] = v;
              } else {
                acc[k] = v;
              }
              return acc;
            }, {}),
            ...params
          }
        ];
      } else {
        paramCalls = range(this.executeTotalCount).map(i => {
          return {
            ...toPairs(this.paramValues).reduce((acc, [k, v]) => {
              // TODO: Add bulk actions
              if (isArray(v)) {
                const indexClean = Math.min(i, v.length - 1);
                acc[k] = v[indexClean];
              } else {
                acc[k] = v;
              }
              return acc;
            }, {}),
            ...params
          };
        });
      }

      if (!paramCalls.length) {
        this.notificationService.error('Nothing to do', 'No items found for execution');
        return;
      }

      this.executeStarted = true;
      this.executeProcessedCount = 0;
      this.executeSucceededCount = 0;
      this.cd.markForCheck();

      this.processParamCalls(paramCalls)
        .pipe(untilDestroyed(this))
        .subscribe(response => {
          const successResult = this.executeSucceededCount == this.executeProcessedCount;

          if (this.currentOptions.showSuccess && successResult) {
            this.notificationService.success('Action Executed', 'Action executed successfully');
          }

          this.onSubmitCompleted(successResult, response);
        });
    }
  }

  onSubmitCompleted(successResult?: boolean, result?: any) {
    this.finished.emit({
      processedCount: this.executeProcessedCount,
      successCount: this.executeSucceededCount,
      failedCount: this.executeProcessedCount - this.executeSucceededCount,
      totalCount: this.executeTotalCount,
      result: result
    });
  }

  processParamCalls(paramCalls: Object[], index = 0): Observable<any> {
    const params = paramCalls[index];
    return this.actionControllerService
      .executeAction(this.action, params, {
        context: this.currentOptions.context,
        contextElement: this.currentOptions.contextElement,
        localContext: this.currentOptions.localContext,
        saveResultTo: this.currentOptions.saveResultTo,
        theme: this.theme,
        injector: this.injector,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        tap(response => {
          this.executeProcessedCount += 1;
          this.executeSucceededCount += 1;
          this.cd.markForCheck();
          this.actionService.processResponse(response);
        }),
        catchError(error => {
          console.error(error);

          this.executeProcessedCount += 1;
          this.cd.markForCheck();

          if (this.currentOptions.showError) {
            if (error instanceof ServerRequestError && error.errors.length) {
              this.notificationService.error('Action Failed', error.errors[0]);
            } else {
              this.notificationService.error('Action Failed', error);
            }
          }

          return of(undefined);
        }),
        switchMap(response => {
          if (index + 1 < paramCalls.length) {
            return this.processParamCalls(paramCalls, index + 1);
          } else {
            return of(response);
          }
        })
      );
  }

  get fieldContext() {
    return {
      action: this.actionDescription ? this.actionDescription.id : undefined
    };
  }
}
