<div *ngIf="form.options.dataSourceControl" class="sidebar__element">
  <app-sidebar-field>
    <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
      <div
        class="sidebar-icon-button"
        [class.sidebar-icon-button_active]="form.controls.export_data_type.value == exportDataTypes.CurrentComponent"
        (click)="setExportDataType(exportDataTypes.CurrentComponent)"
      >
        <div class="sidebar-icon-button__label">This component</div>
      </div>
      <div
        class="sidebar-icon-button"
        [class.sidebar-icon-button_active]="form.controls.export_data_type.value == exportDataTypes.DataSource"
        (click)="setExportDataType(exportDataTypes.DataSource)"
      >
        <div class="sidebar-icon-button__label">Set data source</div>
      </div>
    </div>
  </app-sidebar-field>
</div>

<app-model-description-data-source-edit
  *ngIf="form.controls.export_data_type.value == exportDataTypes.DataSource"
  [control]="form.controls.export_data_source"
  [label]="'choose collection'"
  [queryOptionEmptyLabel]="'No collections'"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [resourceClasses]="'component-action-resource'"
  [queryOptionClasses]="'component-action-collection'"
  [analyticsSource]="analyticsSource + '_export'"
></app-model-description-data-source-edit>

<ng-container *ngIf="(columnsVisible$ | async) && (sortableColumnOptions$ | async) as sortableColumnOptions">
  <div *ngIf="sortableColumnOptions.length" class="sidebar__element">
    <app-sidebar-field [label]="'Sorting'" [classes]="'component-data-sorting'">
      <app-select-field
        [form]="form"
        [label]="false"
        [field]="
          createField({
            name: 'export_sorting_field',
            field: 'SelectField',
            params: {
              options: sortableColumnOptions,
              allow_empty: true,
              empty_name: 'Not specified',
              classes: ['select_fill']
            }
          })
        "
        #sorting_field
      >
      </app-select-field>
      <app-field-errors [form]="form" [fieldName]="'export_sorting_field'"></app-field-errors>
      <app-field-errors [form]="form" [fieldName]="'export_sorting_asc'"></app-field-errors>

      <ng-container right>
        <a
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative button_primary sidebar-field__button"
          [class.button_disabled]="!form.controls.export_sorting_field.value"
          [appTip]="'Change sorting order'"
          (click)="form.toggleExportDefaultSorting()"
        >
          <span
            class="button__icon"
            [class.icon-filter_down]="form.controls.export_sorting_asc.value"
            [class.icon-filter_up]="!form.controls.export_sorting_asc.value"
          ></span>
        </a>
      </ng-container>

      <ng-container description>
        Make sure sorting is stable or you can get duplicated/missing result rows
      </ng-container>
    </app-sidebar-field>
  </div>
</ng-container>

<!--    <div class="sidebar__element">-->
<!--      <app-sidebar-field [label]="'Records'">-->
<!--        <app-input-edit-->
<!--          [itemForm]="form.controls.export_ids"-->
<!--          [context]="context"-->
<!--          [contextElement]="contextElement"-->
<!--          [contextElementPath]="contextElementPath"-->
<!--          [contextElementPaths]="contextElementPaths"-->
<!--          [fill]="true"-->
<!--          [analyticsSource]="object + '_export_ids'"-->
<!--        ></app-input-edit>-->
<!--        <ng-container description>-->
<!--          Comma separated-->
<!--        </ng-container>-->
<!--      </app-sidebar-field>-->
<!--    </div>-->

<app-display-fields-edit-section
  *ngIf="columnsVisible$ | async"
  [resetEnabled]="true"
  [form]="form.controls.export_data_source.controls.columns"
  [modelDescription]="modelDescription"
  [firstInit]="false"
  [analyticsSource]="analyticsSource + '_export'"
  (resetClick)="resetColumns()"
>
</app-display-fields-edit-section>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Records per request'" [additional]="'(optional)'">
    <app-input-edit
      [itemForm]="form.controls.export_per_page"
      [staticValueField]="fieldTypes.Number"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [placeholder]="'100 by default'"
      [fill]="true"
      [analyticsSource]="analyticsSource + '_export_ids'"
    ></app-input-edit>

    <ng-container
      *ngIf="form.controls.export_data_source.controls.query.value?.queryType == queryTypes.SQL"
      description
    >
      When using Records per request make sure export <strong>results are ordered</strong> because batch requests from
      unordered list can lead to incorrect total result
    </ng-container>
  </app-sidebar-field>
</div>
