import { Injectable } from '@angular/core';

import { ActionDescription, ActionType, LinkAction, SegueType, ViewSettingsAction } from '@modules/actions';
import { ActionElementItem } from '@modules/customize';
import { Input, InputValueType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';

import { GeneratorUtils } from '../generator-utils/generator-utils.service';

@Injectable()
export class DeleteButtonGenerator {
  constructor(private generatorUtils: GeneratorUtils) {}

  getElement(
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      pkContextValue: string[];
      listUniqueName?: string;
      uid?: string;
    }
  ): ActionElementItem {
    const deleteInput = new Input();
    const deleteParameterName = this.generatorUtils.getModelDeleteParameter(modelDescription);

    deleteInput.path = [deleteParameterName];
    deleteInput.valueType = InputValueType.Context;
    deleteInput.contextValue = options.pkContextValue;

    const deleteAction = new ViewSettingsAction();
    const deleteActionName = modelDescription
      .autoActions()
      .filter(item => item.name == 'delete')
      .map(item => item.uniqueName)[0];

    deleteAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Delete');
    deleteAction.sharedActionDescription = [resource.uniqueName, deleteActionName].join('.');
    deleteAction.inputs = [deleteInput];
    deleteAction.tint = 'red';
    deleteAction.onSuccessActions = [];

    if (options.listUniqueName) {
      const successLinkAction = new LinkAction();

      successLinkAction.type = SegueType.Page;
      successLinkAction.page = options.listUniqueName;

      const successActionDescription = new ActionDescription();

      successActionDescription.type = ActionType.Link;
      successActionDescription.linkAction = successLinkAction;

      const successAction = new ViewSettingsAction();

      successAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Open List');
      successAction.actionDescription = successActionDescription;

      deleteAction.onSuccessActions = [successAction];
    }

    const deleteActionElement = new ActionElementItem();

    if (options.uid) {
      deleteActionElement.uid = options.uid;
    } else {
      deleteActionElement.generateUid();
    }

    deleteActionElement.actionItem = deleteAction;

    return deleteActionElement;
  }
}
