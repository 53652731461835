import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import { StaticSelectSource } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

import { SelectComponent, SelectSegment } from '@common/select';
import { ViewSettingsAction } from '@modules/actions';
import { ElementActionsPosition, ViewContext } from '@modules/customize';
import {
  applyParamInputs,
  deserializeDisplayField,
  DisplayField,
  FieldType,
  Input,
  OptionsType,
  ParameterField,
  registerFieldComponent
} from '@modules/fields';
import { ModelDescriptionStore, ModelService, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { areObjectsEqual, isSet } from '@shared';

import { Option } from '../../data/option';
import { optionFromGxOption } from '../../utils/select';
import { FieldComponent } from '../field/field.component';

interface State {
  optionsType?: OptionsType;
  currentValue?: any;
  resourceName?: string;
  valueField?: string;
  labelField?: string;
  labelInput?: Input;
  subtitleField?: string;
  subtitleInput?: Input;
  iconField?: string;
  iconInput?: Input;
  colorField?: string;
  colorInput?: Input;
  query?: ListModelDescriptionQuery;
  detailQuery?: ModelDescriptionQuery;
  columns?: DisplayField[];
  parameters?: ParameterField[];
  detailParameters?: ParameterField[];
  inputs?: Input[];
  params?: Object;
  sortingField?: string;
  sortingAsc?: boolean;
  options?: Option[];
}

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild(SelectComponent) selectComponent: SelectComponent;

  dataInputsChange = new Subject<void>();
  firstVisible = false;
  state: State = {};

  updateFieldSubscription: Subscription;
  source: ModelSelectSource;
  staticSource = new StaticSelectSource();
  recordOptions$ = new BehaviorSubject<Option[]>([]);
  recordOptionsSubscription: Subscription;
  options$ = this.recordOptions$.pipe(
    map(options => {
      const result = [];

      if (this.field.params['allow_empty']) {
        result.push({
          value: this.field.params.hasOwnProperty('empty_value') ? this.field.params['empty_value'] : null,
          name: this.field.params['empty_name'] ? this.field.params['empty_name'] : '---'
        });
      }

      if (options) {
        result.push(
          ...options.map(item => {
            return {
              ...item,
              name: isSet(item.name) ? item.name : 'Option is not specified'
            };
          })
        );
      }

      return result;
    })
  );
  valueOption: Option;
  valueOptionSubscription: Subscription;
  topActions: ViewSettingsAction[] = [];
  bottomActions: ViewSettingsAction[] = [];

  modelSelectSource: ModelSelectSource;

  constructor(
    @Optional() private modelDescriptionStore: ModelDescriptionStore,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    super();

    if (this.modelDescriptionStore) {
      this.modelSelectSource = this.createModelSelectSource();
    }
  }

  createModelSelectSource(): ModelSelectSource {
    return Injector.create({
      providers: [
        {
          provide: ModelSelectSource,
          deps: [
            ModelService,
            ReducedModelService,
            ModelDescriptionStore,
            CurrentProjectStore,
            CurrentEnvironmentStore,
            Injector,
            ModelUtilsService
          ]
        }
      ],
      parent: this.injector
    }).get(ModelSelectSource);
  }

  ngOnInit(): void {
    this.dataInputsChange
      .pipe(
        filter(() => this.firstVisible),
        debounceTime(10),
        untilDestroyed(this)
      )
      .subscribe(() => this.onChanges());

    if (this.context instanceof ViewContext) {
      this.context.outputValues$.pipe(untilDestroyed(this)).subscribe(() => this.dataInputsChange.next());
    }

    this.dataInputsChange.next();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (keys(changes).length && keys(changes).some(item => ['field'].includes(item))) {
      this.dataInputsChange.next();
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.open();
    }
  }

  onFirstVisible() {
    this.firstVisible = true;
    this.dataInputsChange.next();
  }

  getChanges(state: State) {
    return {
      newState: state,
      prevState: this.state,
      currentValueChanged: state.currentValue != this.state.currentValue,
      optionsTypeChanged: state.options != this.state.options,
      queryChanged: !areObjectsEqual<State>(state, this.state, [
        'resourceName',
        'valueField',
        'labelField',
        'params',
        item => (item.query ? item.query.serialize() : undefined),
        item => (item.detailQuery ? item.detailQuery.serialize() : undefined)
      ]),
      columnsChanged: !isEqual(state.columns, this.state.columns),
      sortingChanged: !isEqual(
        { sortingField: state.sortingField, sortingAsc: state.sortingAsc },
        { sortingField: this.state.sortingField, sortingAsc: this.state.sortingAsc }
      ),
      optionsChanged: !isEqual(state.options, this.state.options)
    };
  }

  applyStateDefaults(state: State): Observable<State> {
    if (!state.query || state.query.queryType != QueryType.Simple || !this.modelDescriptionStore) {
      return of(state);
    }

    const modelId = [state.resourceName, state.query.simpleQuery.model].join('.');

    return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
      map(modelDescription => {
        if (!modelDescription) {
          return state;
        }

        if (modelDescription.getParameters && state.query.queryType == QueryType.Simple) {
          state.parameters = modelDescription.getParameters;
        }

        if (modelDescription.getDetailQuery) {
          const detailQuery = new ModelDescriptionQuery();

          detailQuery.queryType = QueryType.Simple;
          detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
          detailQuery.simpleQuery.model = modelDescription.model;

          state.detailQuery = detailQuery;
          state.detailParameters = modelDescription.getDetailParametersOrDefaults;
        }

        return state;
      })
    );
  }

  onChanges() {
    const parameters = this.field.params['parameters']
      ? this.field.params['parameters'].map(item => new ParameterField().deserialize(item))
      : [];
    const inputs = this.field.params['inputs']
      ? this.field.params['inputs'].map(item => new Input().deserialize(item))
      : [];
    const state: State = {
      currentValue: this.currentValue,
      optionsType: this.field.params['options_type'] || OptionsType.Static,
      resourceName: this.field.params['resource'],
      valueField: this.field.params['value_field'],
      labelField: this.field.params['label_field'],
      labelInput: this.field.params['label_field_input']
        ? new Input().deserialize(this.field.params['label_field_input'])
        : undefined,
      subtitleField: this.field.params['subtitle_field'],
      subtitleInput: this.field.params['subtitle_input']
        ? new Input().deserialize(this.field.params['subtitle_input'])
        : undefined,
      iconField: this.field.params['icon_field'],
      iconInput: this.field.params['icon_input'] ? new Input().deserialize(this.field.params['icon_input']) : undefined,
      colorField: this.field.params['color_field'],
      colorInput: this.field.params['color_input']
        ? new Input().deserialize(this.field.params['color_input'])
        : undefined,
      query: this.field.params['query']
        ? new ListModelDescriptionQuery().deserialize(this.field.params['query'])
        : undefined,
      columns: this.field.params['columns']
        ? this.field.params['columns'].map(item => deserializeDisplayField(item))
        : undefined,
      parameters: parameters,
      inputs: inputs,
      sortingField: this.field.params['sorting_field'],
      sortingAsc: this.field.params['sorting_asc'],
      options: this.field.params['options']
    };

    if (this.updateFieldSubscription) {
      this.updateFieldSubscription.unsubscribe();
    }

    this.updateFieldSubscription = this.applyStateDefaults(state)
      .pipe(untilDestroyed(this))
      .subscribe(newState => {
        newState.params = applyParamInputs({}, state.inputs, { context: this.context, parameters: state.parameters });

        const changes = this.getChanges(newState);

        this.state = changes.newState;

        if (
          changes.currentValueChanged ||
          changes.optionsTypeChanged ||
          changes.queryChanged ||
          changes.columnsChanged ||
          changes.sortingChanged ||
          changes.optionsChanged
        ) {
          this.updateOptions(changes.newState);
        }

        this.updateActions();
      });
  }

  updateOptions(state: State) {
    if (this.recordOptionsSubscription) {
      this.recordOptionsSubscription.unsubscribe();
      this.recordOptionsSubscription = undefined;
    }

    if (state.optionsType == OptionsType.Query) {
      if (this.modelSelectSource) {
        this.modelSelectSource.init({
          resource: state.resourceName,
          query: state.query,
          queryParameters: state.parameters || [],
          detailQuery: state.detailQuery,
          detailQueryParameters: state.detailParameters || [],
          columns: state.columns,
          valueField: state.valueField,
          nameField: state.labelField,
          nameInput: state.labelInput,
          subtitleField: state.subtitleField,
          subtitleInput: state.subtitleInput,
          iconField: state.iconField,
          iconInput: state.iconInput,
          colorField: state.colorField,
          colorInput: state.colorInput,
          params: state.params,
          sortingField: state.sortingField,
          sortingAsc: state.sortingAsc,
          context: this.context,
          contextElement: this.contextElement
        });
        this.modelSelectSource.reset();
      }

      this.source = this.modelSelectSource;
      this.recordOptions$.next([]);
      this.cd.markForCheck();
    } else {
      const options = [];

      if (state.options) {
        options.push(...state.options);
      }

      this.source = undefined;
      this.recordOptions$.next(options);
      this.cd.markForCheck();
    }

    this.updateValueOption();
  }

  updateValueOption() {
    if (this.valueOptionSubscription) {
      this.valueOptionSubscription.unsubscribe();
      this.valueOptionSubscription = undefined;
    }

    let obs: Observable<Option>;

    if (this.source) {
      if (this.modelSelectSource) {
        obs = this.modelSelectSource.fetchByValue(this.currentValue).pipe(
          map(option => {
            if (!option) {
              return;
            }

            const optionSingle = isArray(option) ? option[0] : option;
            return optionFromGxOption(optionSingle);
          })
        );
      }
    } else {
      obs = this.recordOptions$.pipe(
        map(options => {
          const option = options.find(item => item.value == this.currentValue);

          if (!option) {
            if (this.currentValue) {
              return { name: this.currentValue, value: this.currentValue };
            } else {
              return;
            }
          }

          return option;
        })
      );
    }

    if (!obs) {
      return;
    }

    this.valueOptionSubscription = obs.pipe(untilDestroyed(this)).subscribe(
      result => {
        this.valueOption = result;
        this.cd.markForCheck();
      },
      () => {
        this.valueOption = undefined;
        this.cd.markForCheck();
      }
    );
  }

  updateActions() {
    const topActions = this.elementActions.find(item => item.position == ElementActionsPosition.Top);
    const bottomActions = this.elementActions.find(item => item.position == ElementActionsPosition.Bottom);

    this.topActions = topActions ? topActions.actions : [];
    this.bottomActions = bottomActions ? bottomActions.actions : [];
    this.cd.markForCheck();
  }

  // TODO: Move to separate params option
  get selectFill() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('select_fill') != -1;
  }

  // TODO: Move to separate params option
  get selectSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('select_small') != -1;
  }

  // TODO: Move to separate params option
  get selectSegment(): SelectSegment {
    if (!this.field.params['classes']) {
      return;
    }

    if (this.field.params['classes'].indexOf('select_segment-top') != -1) {
      return SelectSegment.Top;
    } else if (this.field.params['classes'].indexOf('select_segment') != -1) {
      return SelectSegment.Middle;
    } else if (this.field.params['classes'].indexOf('select_segment-bottom') != -1) {
      return SelectSegment.Bottom;
    }
  }

  backgroundCustomColor(color: string) {
    try {
      const clr = Color(color);
      return clr.alpha(0.1).string();
    } catch (e) {
      return null;
    }
  }

  open() {
    if (this.selectComponent) {
      this.selectComponent.open();
    }
  }

  asActions(value: any): ViewSettingsAction[] {
    return value as ViewSettingsAction[];
  }

  asSelectComponent(value: any): SelectComponent {
    return value as SelectComponent;
  }
}

registerFieldComponent(FieldType.Select, SelectFieldComponent);
