import { InjectionToken, Injector, Type } from '@angular/core';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import { Observable, of } from 'rxjs';

import { ActionDescription, ActionResponse } from '@modules/actions';
import { ApiInfo } from '@modules/api';
import { AggregateFunc, DataGroup, DatasetGroupLookup } from '@modules/charts';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { lookups } from '@modules/field-lookups';
import { DisplayField, InputValueType, ParameterField, parseFilterName } from '@modules/fields';
import { FilterItem2, Sort } from '@modules/filters';
import {
  BUILT_IN_PARAMS,
  CURSOR_NEXT_PARAM,
  CURSOR_PREV_PARAM,
  Model,
  ModelDbField,
  ModelDescription,
  ModelField,
  ModelRelation,
  NO_PAGINATION_PARAM,
  ORDER_BY_PARAM,
  PAGE_PARAM,
  PER_PAGE_PARAM,
  SEARCH_PARAM
} from '@modules/models';
import { Resource, ResourceTypeItem } from '@modules/projects';
import {
  ActionQuery,
  ListModelDescriptionQuery,
  ObjectQuery,
  ObjectQueryOperation,
  QueryType,
  StorageQuery
} from '@modules/queries';
import { Storage, StorageObject, StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';

import { ModelResponse } from './model-response';

export interface ActionExecuteParams {
  model?: string;
  id?: any;
  ids?: any[];
  inverseIds?: boolean;
  actionParams?: Object;
  modelInstance?: Model;
}

export interface GetQueryOptionsPaging {
  page?: number;
  limit?: number;
  cursorPrev?: any;
  cursorNext?: any;
  disableCount?: boolean;
}

export interface GetQueryOptions {
  paging?: GetQueryOptionsPaging;
  filters?: FilterItem2[];
  params?: Object;
  search?: string;
  sort?: Sort[];
  columns?: DisplayField[];
  modelDescriptions?: ModelDescription[];
}

export interface GetObjectUrlResponse {
  url: string;
}

export class StorageBucketResponse {
  name: string;
  creationDate: string | null;

  deserialize(data: Object): this {
    this.name = data['name'];
    this.creationDate = data['creation_date'];

    return this;
  }
}

export class StorageBucketsResponse {
  buckets: StorageBucketResponse[] = [];

  deserialize(data: Object): this {
    if (data['buckets']) {
      this.buckets = data['buckets'].map(item => new StorageBucketResponse().deserialize(item));
    }

    return this;
  }
}

export function getQueryOptionsToParams(options: GetQueryOptions): Object {
  const result = {};

  if (options.paging && isSet(options.paging.page)) {
    result[PAGE_PARAM] = options.paging.page;
  }

  if (options.paging && isSet(options.paging.limit)) {
    result[PER_PAGE_PARAM] = options.paging.limit;
  }

  if (options.paging && isSet(options.paging.cursorPrev)) {
    result[CURSOR_PREV_PARAM] = options.paging.cursorPrev;
  }

  if (options.paging && isSet(options.paging.cursorNext)) {
    result[CURSOR_NEXT_PARAM] = options.paging.cursorNext;
  }

  if (options.paging && isSet(options.paging.disableCount)) {
    result[NO_PAGINATION_PARAM] = '1';
  }

  if (options.filters) {
    options.filters.forEach(filter => {
      result[filter.getName()] = filter.value;
    });
  }

  if (options.params) {
    toPairs(options.params).forEach(([k, v]) => {
      result[k] = v;
    });
  }

  if (isSet(options.search)) {
    result[SEARCH_PARAM] = options.search;
  }

  if (options.sort && options.sort.length) {
    const sort = options.sort[0];
    result[ORDER_BY_PARAM] = sort.desc ? `-${sort.field}` : sort.field;
  }

  return result;
}

export function paramsToGetQueryOptions(params: Object): GetQueryOptions {
  const result: GetQueryOptions = {};

  if (params.hasOwnProperty(PAGE_PARAM)) {
    result.paging = result.paging || {};
    result.paging.page = params[PAGE_PARAM];
  }

  if (params.hasOwnProperty(PER_PAGE_PARAM)) {
    result.paging = result.paging || {};
    result.paging.limit = params[PER_PAGE_PARAM];
  }

  if (params.hasOwnProperty(CURSOR_PREV_PARAM)) {
    result.paging = result.paging || {};
    result.paging.cursorPrev = params[CURSOR_PREV_PARAM];
  }

  if (params.hasOwnProperty(CURSOR_NEXT_PARAM)) {
    result.paging = result.paging || {};
    result.paging.cursorNext = params[CURSOR_NEXT_PARAM];
  }

  if (params.hasOwnProperty(NO_PAGINATION_PARAM)) {
    result.paging = result.paging || {};
    result.paging.disableCount = true;
  }

  result.filters = keys(params)
    .filter(key => !BUILT_IN_PARAMS.includes(key))
    .filter(key => params[key] !== undefined)
    .map(key => {
      const { field, path, lookup: lookupName, exclude } = parseFilterName(key);

      if (!isSet(field)) {
        return;
      }

      const lookup = isSet(lookupName) ? lookups.find(i => i.lookup == lookupName) : undefined;

      return new FilterItem2({
        field: path,
        lookup: lookup,
        value: params[key],
        exclude: exclude
      });
    })
    .filter(item => item);

  if (params.hasOwnProperty(SEARCH_PARAM)) {
    result.search = params[SEARCH_PARAM];
  }

  if (params.hasOwnProperty(ORDER_BY_PARAM)) {
    const [field, ascending] = params[ORDER_BY_PARAM].startsWith('-')
      ? [params[ORDER_BY_PARAM].slice(1), false]
      : [params[ORDER_BY_PARAM], true];

    result.sort = [{ field: field, desc: !ascending }];
  }

  return result;
}

export function applyQueryOptionsFilterInputs(
  dataSource: ListModelDescriptionDataSource,
  queryOptions: GetQueryOptions
): GetQueryOptions {
  if (!dataSource) {
    return queryOptions;
  }

  const result: GetQueryOptions = {
    ...queryOptions,
    filters: queryOptions.filters ? [...queryOptions.filters] : [],
    params: { ...queryOptions.params }
  };

  dataSource.queryInputs
    .filter(item => item.valueType == InputValueType.Filter)
    .forEach(input => {
      if (!dataSource) {
        return;
      }

      const column = dataSource.columns.find(item => item.name == input.filterField);
      if (!column) {
        return;
      }

      const inputLookup = lookups.find(item => item.lookup == input.filterLookup);
      const inputLookupName = inputLookup ? inputLookup.lookup : undefined;
      const filterIndex = result.filters.findIndex(item => {
        const itemLookupName = item.lookup ? item.lookup.lookup : undefined;
        return isEqual(item.field, [column.name]) && itemLookupName === inputLookupName && !item.exclude;
      });

      if (filterIndex !== -1) {
        const filter = result.filters[filterIndex];
        result.params[input.getName()] = filter.value;
        result.filters.splice(filterIndex, 1);
      }
    });

  return result;
}

export interface SqlQueryAggregate {
  func: AggregateFunc;
  column?: string;
}

export interface SqlQueryGroup {
  xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[];
  yFunc: AggregateFunc;
  yColumn?: string;
}

export interface SqlQueryOptions {
  tokens?: Object;
  offset?: number;
  limit?: number;
  orderBy?: string[];
  count?: boolean;
  columns?: { name: string; data_type: string }[];
  filters?: { name: string; value: any }[];
  aggregate?: SqlQueryAggregate;
  groups?: SqlQueryGroup;
  version?: number;
}

export interface ObjectQueryOptions {
  objectToArray?: boolean;
}

export abstract class ResourceController {
  filtersExcludable = false;
  filtersLookups = false;
  relationFilter = false;

  constructor(protected injector: Injector) {
    this.init();
  }

  init() {}

  initService<T>(cls: Type<T> | InjectionToken<T>) {
    try {
      return this.injector.get<T>(cls);
    } catch (e) {}
  }

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  createGetResponse(): ModelResponse.GetResponse {
    return Injector.create({
      providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
      parent: this.injector
    }).get<ModelResponse.GetResponse>(ModelResponse.GetResponse);
  }

  abstract supportedQueryTypes(resource: ResourceTypeItem, queryClass: any): QueryType[];

  getApiInfo?(resource: Resource): Observable<ApiInfo>;

  checkApiInfo?(resource: Resource): Observable<Resource>;

  checkResource?(typeItem: ResourceTypeItem, params: Object): Observable<boolean>;

  supportModelDescriptionManagement?(resource: Resource): boolean {
    return false;
  }

  modelDescriptionGet?(resource: Resource, draft?: boolean): Observable<ModelDescription[]>;

  setModelDescriptionRelationOverrides?(
    resource: Resource,
    items: { modelDescription: ModelDescription; relations: ModelRelation[] }[],
    draft?: boolean
  ): Observable<ModelRelation[]>;

  modelDescriptionCreate?(resource: Resource, modelDescription: ModelDescription): Observable<Object>;

  modelDescriptionDelete?(resource: Resource, modelDescription: ModelDescription): Observable<Object>;

  modelDescriptionFieldCreate?(
    resource: Resource,
    modelDescription: ModelDescription,
    field: ModelDbField
  ): Observable<Object>;

  modelDescriptionFieldUpdate?(
    resource: Resource,
    modelDescription: ModelDescription,
    name: string,
    field: ModelDbField
  ): Observable<Object>;

  modelDescriptionFieldDelete?(
    resource: Resource,
    modelDescription: ModelDescription,
    name: string
  ): Observable<Object>;

  modelGet?(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse>;

  modelGetAdv?(
    resource: Resource,
    modelDescription: ModelDescription,
    options: GetQueryOptions
  ): Observable<ModelResponse.GetResponse>;

  modelGetDetail?(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model>;

  modelGetDetailAdv?(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    options: GetQueryOptions
  ): Observable<Model>;

  modelCreate?(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model>;

  modelCreateBulk?(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstances: Model[],
    fields?: string[]
  ): Observable<{ success: boolean; errors?: Object }[]>;

  modelUpdate?(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model>;

  modelDelete?(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object>;

  modelReorder?(
    resource: Resource,
    modelDescription: ModelDescription,
    forward: boolean,
    segmentFrom: number,
    segmentTo: number,
    item: number,
    segmentByOrderingField?: boolean
  );

  modelResetOrder?(
    resource: Resource,
    modelDescription: ModelDescription,
    ordering?: string,
    valueOrdering?: string
  ): Observable<Object>;

  modelAggregate?(
    resource: Resource,
    modelDescription: ModelDescription,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any>;

  modelGroup?(
    resource: Resource,
    modelDescription: ModelDescription,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<DataGroup[]>;

  modelGetSiblings?(
    resource: Resource,
    modelDescription: ModelDescription,
    id: string,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.SiblingsResponse>;

  sql?(resource: Resource, query: string, options?: SqlQueryOptions): Observable<ModelResponse.SqlResponse>;

  sqls?(
    resource: Resource,
    queries: { query: string; options?: SqlQueryOptions }[]
  ): Observable<ModelResponse.SqlResponse[]>;

  objectQuery<T>(resource: Resource, query: ObjectQuery, data: Object = {}): Observable<Object> {
    if (query.operation == ObjectQueryOperation.Get) {
      return this.objectGet(resource, query.query, query.queryOptions) as Observable<T>;
    } else if (query.operation == ObjectQueryOperation.Create) {
      return this.objectCreate(resource, query.query, data) as Observable<T>;
    } else if (query.operation == ObjectQueryOperation.Update) {
      return this.objectUpdate(resource, query.query, data) as Observable<T>;
    } else if (query.operation == ObjectQueryOperation.Delete) {
      return this.objectDelete(resource, query.query, data);
    } else {
      return of(undefined);
    }
  }

  objectGet?(resource: Resource, path?: PropertyKey[], options?: ObjectQueryOptions): Observable<Object>;

  objectCreate?(resource: Resource, path: PropertyKey[], data: Object): Observable<Object>;

  objectUpdate?(resource: Resource, path: PropertyKey[], data: Object): Observable<Object>;

  objectDelete?(resource: Resource, path: PropertyKey[], data: Object): Observable<Object>;

  uploadFile?(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse>;

  getStorageObjects?(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string
  ): Observable<StorageObjectsResponse>;

  deleteStorageObject?(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object>;
  createStorageFolder?(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object>;
  getObjectUrl?(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string,
    expiresInSec?: number
  ): Observable<GetObjectUrlResponse>;

  fileUrl?(resource: Resource, value: any): string;

  actionDescriptionGet?(resource: Resource): Observable<ActionDescription[]>;

  actionExecute?(
    resource: Resource,
    query: ActionQuery,
    parameters?: ParameterField[],
    params?: ActionExecuteParams,
    rawErrors?: boolean
  ): Observable<ActionResponse>;

  reload?(resource: Resource): Observable<boolean>;

  setUpModelDescriptionBasedOnGetQuery(
    resource: Resource,
    modelDescription: ModelDescription,
    getQuery: ListModelDescriptionQuery,
    fields: ModelField[]
  ): ModelDescription {
    modelDescription.fields = fields;

    return modelDescription;
  }
}
