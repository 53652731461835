import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import assign from 'lodash/assign';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { delay, distinctUntilChanged, map, skip } from 'rxjs/operators';

import { RawListViewSettingsColumn } from '@modules/customize';
import { Option } from '@modules/field-components';
import { FieldLookup, lookups, serializeFieldParamName } from '@modules/field-lookups';
import {
  defaultFieldType,
  FieldDescriptionLookup,
  getFieldDescriptionByType,
  Input,
  InputValueType,
  ParameterField
} from '@modules/fields';
import { FilterItem } from '@modules/filters';
import { controlValue, isSet } from '@shared';

export const validateLookupValue: ValidatorFn = control => {
  const lookup = control.value['lookup'] as FieldDescriptionLookup;
  const lookupValue = control.value['value'];

  if (lookup && lookup.field && !isSet(lookupValue)) {
    return { required: true };
  }
};

@Injectable()
export class AddFilterPopupForm {
  field: string;
  columns: RawListViewSettingsColumn[] = [];
  parameters: ParameterField[] = [];
  inputs: Input[];

  form = new FormGroup(
    {
      field: new FormControl('', Validators.required),
      lookup: new FormControl(undefined),
      value: new FormControl('')
    },
    validateLookupValue
  );

  fieldOptions$ = new BehaviorSubject<Option<string>[]>([]);

  init(
    field: string,
    columns: RawListViewSettingsColumn[],
    parameters: ParameterField[],
    inputs: Input[],
    filterItem?: FilterItem
  ) {
    this.field = field;
    this.columns = columns;
    this.parameters = parameters;
    this.inputs = inputs;

    this.form = new FormGroup(
      {
        field: new FormControl(this.field, Validators.required),
        lookup: new FormControl(undefined),
        value: new FormControl('')
      },
      validateLookupValue
    );

    const input = filterItem
      ? this.inputs.find(i => {
          const name = filterItem.getName();
          if (i.valueType == InputValueType.Filter) {
            const itemName = serializeFieldParamName(i.filterField, i.filterLookup, false);
            return itemName == name;
          } else if (i.getName() == name) {
            return true;
          }
        })
      : undefined;

    if (filterItem && input) {
      const lookup = this.getFieldLookups(input.filterField).find(item => item.type.lookup === input.filterLookup);
      const fieldDescription = lookup ? getFieldDescriptionByType(lookup.field) : undefined;
      let value = filterItem.value;

      if (fieldDescription && fieldDescription.deserializeValue) {
        value = fieldDescription.deserializeValue(value);
      }

      this.form.patchValue({
        field: input.filterField,
        lookup: lookup,
        value: value
      });
    } else {
      this.form.patchValue({
        field: this.field
      });
      this.setValueDefault();
    }

    this.updateFieldOptions();

    this.lookups$.subscribe(fieldLookups => {
      const lookup = fieldLookups.find(
        item => this.form.value['lookup'] && item.type.lookup === this.form.value['lookup'].type.lookup
      );

      this.form.patchValue({
        lookup: lookup || fieldLookups[0]
      });
    });

    controlValue(this.form.controls['lookup'])
      .pipe(distinctUntilChanged(), skip(1), delay(0))
      .subscribe(() => this.setValueDefault());
  }

  setValueDefault() {
    const lookup = this.form.controls['lookup'].value;
    const fieldType = lookup ? lookup.field : defaultFieldType;
    const fieldDescription = getFieldDescriptionByType(fieldType);
    this.form.patchValue({ value: fieldDescription.defaultValue });
  }

  updateFieldOptions() {
    this.fieldOptions$.next(
      this.columns
        .filter(item =>
          this.inputs.find(input => input.valueType == InputValueType.Filter && input.filterField == item.name)
        )
        .map(item => {
          return {
            value: item.name,
            name: item.verboseName || item.name
          };
        })
    );
  }

  get lookups$(): Observable<any[]> {
    return controlValue<string>(this.form.controls['field']).pipe(map(fieldName => this.getFieldLookups(fieldName)));
  }

  get valueField$(): Observable<string> {
    return controlValue<FieldDescriptionLookup>(this.form.controls['lookup']).pipe(
      map(lookup => (lookup ? lookup.field : undefined))
    );
  }

  get valueFieldParams$(): Observable<Object> {
    return combineLatest(
      controlValue<string>(this.form.controls['field']),
      controlValue<FieldDescriptionLookup>(this.form.controls['lookup'])
    ).pipe(
      map(([fieldName, lookup]) => {
        if (!fieldName) {
          return {};
        }

        if (lookup) {
          const params = {};
          const input = this.inputs.find(
            i =>
              i.valueType == InputValueType.Filter &&
              i.filterField == fieldName &&
              i.filterLookup === (lookup ? lookup.type.lookup : undefined)
          );
          const column = input ? this.columns.find(item => item.name == input.filterField) : undefined;
          const parameter = input ? this.parameters.find(item => input.isName(item.name)) : undefined;

          if (parameter) {
            assign(params, parameter.params);
          }

          if (lookup.fieldParams && column) {
            lookup.fieldParams.forEach(param => (params[param] = column.params[param]));
          }

          if (lookup.extraParams) {
            assign(params, lookup.extraParams);
          }

          return params;
        } else {
          return {};
        }
      })
    );
  }

  get valueFieldLookup$(): Observable<FieldLookup> {
    return controlValue<FieldDescriptionLookup>(this.form.controls['lookup']).pipe(
      map(lookup => {
        if (!lookup) {
          return;
        }

        return lookups.find(item => item === lookup.type);
      })
    );
  }

  getFieldLookups(fieldName: string) {
    const field = this.columns.find(item => item.name == fieldName);
    const enabledLookups = this.inputs
      .filter(input => input.valueType == InputValueType.Filter && input.filterField == fieldName)
      .map(input => input.filterLookup);

    const fieldDescription = field ? getFieldDescriptionByType(field.field) : undefined;

    return fieldDescription ? fieldDescription.lookups.filter(item => enabledLookups.includes(item.type.lookup)) : [];
  }

  submit(filter: boolean): FilterItem {
    const value = this.form.value;
    const result = new FilterItem();
    const lookup = value['lookup'];

    const input = this.inputs.find(
      item =>
        item.valueType == InputValueType.Filter &&
        item.filterField == value['field'] &&
        item.filterLookup === (value['lookup'] ? value['lookup'].type.lookup : undefined)
    );

    if (!input) {
      return;
    }

    result.field = input.filterField;
    result.lookup = lookup.type;

    if (lookup && !lookup.field) {
      result.value = true;
    } else {
      result.value = value['value'];

      const fieldDescription = lookup ? getFieldDescriptionByType(lookup.field) : undefined;

      if (fieldDescription && fieldDescription.serializeValue) {
        result.value = fieldDescription.serializeValue(result.value, value['value']);
      }
    }

    result.exclude = !filter;

    return result;
  }
}
