var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import { of } from 'rxjs';
import { lookups } from '@modules/field-lookups';
import { InputValueType, parseFilterName } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { BUILT_IN_PARAMS, CURSOR_NEXT_PARAM, CURSOR_PREV_PARAM, Model, NO_PAGINATION_PARAM, ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { ObjectQueryOperation } from '@modules/queries';
import { isSet } from '@shared';
import { ModelResponse } from './model-response';
var StorageBucketResponse = /** @class */ (function () {
    function StorageBucketResponse() {
    }
    StorageBucketResponse.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.creationDate = data['creation_date'];
        return this;
    };
    return StorageBucketResponse;
}());
export { StorageBucketResponse };
var StorageBucketsResponse = /** @class */ (function () {
    function StorageBucketsResponse() {
        this.buckets = [];
    }
    StorageBucketsResponse.prototype.deserialize = function (data) {
        if (data['buckets']) {
            this.buckets = data['buckets'].map(function (item) { return new StorageBucketResponse().deserialize(item); });
        }
        return this;
    };
    return StorageBucketsResponse;
}());
export { StorageBucketsResponse };
export function getQueryOptionsToParams(options) {
    var result = {};
    if (options.paging && isSet(options.paging.page)) {
        result[PAGE_PARAM] = options.paging.page;
    }
    if (options.paging && isSet(options.paging.limit)) {
        result[PER_PAGE_PARAM] = options.paging.limit;
    }
    if (options.paging && isSet(options.paging.cursorPrev)) {
        result[CURSOR_PREV_PARAM] = options.paging.cursorPrev;
    }
    if (options.paging && isSet(options.paging.cursorNext)) {
        result[CURSOR_NEXT_PARAM] = options.paging.cursorNext;
    }
    if (options.paging && isSet(options.paging.disableCount)) {
        result[NO_PAGINATION_PARAM] = '1';
    }
    if (options.filters) {
        options.filters.forEach(function (filter) {
            result[filter.getName()] = filter.value;
        });
    }
    if (options.params) {
        toPairs(options.params).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            result[k] = v;
        });
    }
    if (isSet(options.search)) {
        result[SEARCH_PARAM] = options.search;
    }
    if (options.sort && options.sort.length) {
        var sort = options.sort[0];
        result[ORDER_BY_PARAM] = sort.desc ? "-" + sort.field : sort.field;
    }
    return result;
}
export function paramsToGetQueryOptions(params) {
    var result = {};
    if (params.hasOwnProperty(PAGE_PARAM)) {
        result.paging = result.paging || {};
        result.paging.page = params[PAGE_PARAM];
    }
    if (params.hasOwnProperty(PER_PAGE_PARAM)) {
        result.paging = result.paging || {};
        result.paging.limit = params[PER_PAGE_PARAM];
    }
    if (params.hasOwnProperty(CURSOR_PREV_PARAM)) {
        result.paging = result.paging || {};
        result.paging.cursorPrev = params[CURSOR_PREV_PARAM];
    }
    if (params.hasOwnProperty(CURSOR_NEXT_PARAM)) {
        result.paging = result.paging || {};
        result.paging.cursorNext = params[CURSOR_NEXT_PARAM];
    }
    if (params.hasOwnProperty(NO_PAGINATION_PARAM)) {
        result.paging = result.paging || {};
        result.paging.disableCount = true;
    }
    result.filters = keys(params)
        .filter(function (key) { return !BUILT_IN_PARAMS.includes(key); })
        .filter(function (key) { return params[key] !== undefined; })
        .map(function (key) {
        var _a = parseFilterName(key), field = _a.field, path = _a.path, lookupName = _a.lookup, exclude = _a.exclude;
        if (!isSet(field)) {
            return;
        }
        var lookup = isSet(lookupName) ? lookups.find(function (i) { return i.lookup == lookupName; }) : undefined;
        return new FilterItem2({
            field: path,
            lookup: lookup,
            value: params[key],
            exclude: exclude
        });
    })
        .filter(function (item) { return item; });
    if (params.hasOwnProperty(SEARCH_PARAM)) {
        result.search = params[SEARCH_PARAM];
    }
    if (params.hasOwnProperty(ORDER_BY_PARAM)) {
        var _a = params[ORDER_BY_PARAM].startsWith('-')
            ? [params[ORDER_BY_PARAM].slice(1), false]
            : [params[ORDER_BY_PARAM], true], field = _a[0], ascending = _a[1];
        result.sort = [{ field: field, desc: !ascending }];
    }
    return result;
}
export function applyQueryOptionsFilterInputs(dataSource, queryOptions) {
    if (!dataSource) {
        return queryOptions;
    }
    var result = __assign({}, queryOptions, { filters: queryOptions.filters ? queryOptions.filters.slice() : [], params: __assign({}, queryOptions.params) });
    dataSource.queryInputs
        .filter(function (item) { return item.valueType == InputValueType.Filter; })
        .forEach(function (input) {
        if (!dataSource) {
            return;
        }
        var column = dataSource.columns.find(function (item) { return item.name == input.filterField; });
        if (!column) {
            return;
        }
        var inputLookup = lookups.find(function (item) { return item.lookup == input.filterLookup; });
        var inputLookupName = inputLookup ? inputLookup.lookup : undefined;
        var filterIndex = result.filters.findIndex(function (item) {
            var itemLookupName = item.lookup ? item.lookup.lookup : undefined;
            return isEqual(item.field, [column.name]) && itemLookupName === inputLookupName && !item.exclude;
        });
        if (filterIndex !== -1) {
            var filter = result.filters[filterIndex];
            result.params[input.getName()] = filter.value;
            result.filters.splice(filterIndex, 1);
        }
    });
    return result;
}
var ResourceController = /** @class */ (function () {
    function ResourceController(injector) {
        this.injector = injector;
        this.filtersExcludable = false;
        this.filtersLookups = false;
        this.relationFilter = false;
        this.init();
    }
    ResourceController.prototype.init = function () { };
    ResourceController.prototype.initService = function (cls) {
        try {
            return this.injector.get(cls);
        }
        catch (e) { }
    };
    ResourceController.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    ResourceController.prototype.createGetResponse = function () {
        return Injector.create({
            providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
            parent: this.injector
        }).get(ModelResponse.GetResponse);
    };
    ResourceController.prototype.supportModelDescriptionManagement = function (resource) {
        return false;
    };
    ResourceController.prototype.objectQuery = function (resource, query, data) {
        if (data === void 0) { data = {}; }
        if (query.operation == ObjectQueryOperation.Get) {
            return this.objectGet(resource, query.query, query.queryOptions);
        }
        else if (query.operation == ObjectQueryOperation.Create) {
            return this.objectCreate(resource, query.query, data);
        }
        else if (query.operation == ObjectQueryOperation.Update) {
            return this.objectUpdate(resource, query.query, data);
        }
        else if (query.operation == ObjectQueryOperation.Delete) {
            return this.objectDelete(resource, query.query, data);
        }
        else {
            return of(undefined);
        }
    };
    ResourceController.prototype.setUpModelDescriptionBasedOnGetQuery = function (resource, modelDescription, getQuery, fields) {
        modelDescription.fields = fields;
        return modelDescription;
    };
    return ResourceController;
}());
export { ResourceController };
