var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as Color from 'color';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import { StaticSelectSource } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { TintStyle } from '@modules/actions';
import { ViewContext } from '@modules/customize';
import { applyParamInputs, deserializeDisplayField, FieldType, Input, OptionsType, ParameterField, registerFieldComponent } from '@modules/fields';
import { ModelDescriptionStore, ModelService, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { areObjectsEqual } from '@shared';
import { optionFromGxOption } from '../../utils/select';
import { FieldComponent } from '../field/field.component';
var RadioButtonFieldComponent = /** @class */ (function (_super) {
    __extends(RadioButtonFieldComponent, _super);
    function RadioButtonFieldComponent(modelDescriptionStore, injector, cd) {
        var _this = _super.call(this) || this;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.injector = injector;
        _this.cd = cd;
        _this.dataInputsChange = new Subject();
        _this.firstVisible = false;
        _this.state = {};
        _this.staticSource = new StaticSelectSource();
        _this.recordOptions$ = new BehaviorSubject([]);
        _this.options$ = _this.recordOptions$.pipe(map(function (options) {
            var result = [];
            if (_this.field.params['allow_empty']) {
                result.push({
                    value: null,
                    name: _this.field.params['empty_name'] ? _this.field.params['empty_name'] : '---'
                });
            }
            if (options) {
                result.push.apply(result, options);
            }
            return result;
        }));
        _this.tintStyles = TintStyle;
        if (_this.modelDescriptionStore) {
            _this.modelSelectSource = _this.createModelSelectSource();
        }
        return _this;
    }
    RadioButtonFieldComponent.prototype.createModelSelectSource = function () {
        return Injector.create({
            providers: [
                {
                    provide: ModelSelectSource,
                    deps: [
                        ModelService,
                        ReducedModelService,
                        ModelDescriptionStore,
                        CurrentProjectStore,
                        CurrentEnvironmentStore,
                        Injector,
                        ModelUtilsService
                    ]
                }
            ],
            parent: this.injector
        }).get(ModelSelectSource);
    };
    RadioButtonFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataInputsChange
            .pipe(filter(function () { return _this.firstVisible; }), debounceTime(10), untilDestroyed(this))
            .subscribe(function () { return _this.onChanges(); });
        if (this.context instanceof ViewContext) {
            this.context.outputValues$.pipe(untilDestroyed(this)).subscribe(function () { return _this.dataInputsChange.next(); });
        }
        this.dataInputsChange.next();
    };
    RadioButtonFieldComponent.prototype.ngOnDestroy = function () { };
    RadioButtonFieldComponent.prototype.ngOnChanges = function (changes) {
        if (keys(changes).length && keys(changes).some(function (item) { return ['field'].includes(item); })) {
            this.dataInputsChange.next();
        }
    };
    RadioButtonFieldComponent.prototype.onFirstVisible = function () {
        this.firstVisible = true;
        this.dataInputsChange.next();
    };
    RadioButtonFieldComponent.prototype.getChanges = function (state) {
        return {
            newState: state,
            prevState: this.state,
            currentValueChanged: state.currentValue != this.state.currentValue,
            optionsTypeChanged: state.options != this.state.options,
            queryChanged: !areObjectsEqual(state, this.state, [
                'resourceName',
                'valueField',
                'labelField',
                'params',
                function (item) { return (item.query ? item.query.serialize() : undefined); },
                function (item) { return (item.detailQuery ? item.detailQuery.serialize() : undefined); }
            ]),
            columnsChanged: !isEqual(state.columns, this.state.columns),
            sortingChanged: !isEqual({ sortingField: state.sortingField, sortingAsc: state.sortingAsc }, { sortingField: this.state.sortingField, sortingAsc: this.state.sortingAsc }),
            optionsChanged: !isEqual(state.options, this.state.options)
        };
    };
    RadioButtonFieldComponent.prototype.applyStateDefaults = function (state) {
        if (!state.query || state.query.queryType != QueryType.Simple || !this.modelDescriptionStore) {
            return of(state);
        }
        var modelId = [state.resourceName, state.query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
            if (!modelDescription) {
                return state;
            }
            if (modelDescription.getParameters && state.query.queryType == QueryType.Simple) {
                state.parameters = modelDescription.getParameters;
            }
            if (modelDescription.getDetailQuery) {
                var detailQuery = new ModelDescriptionQuery();
                detailQuery.queryType = QueryType.Simple;
                detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
                detailQuery.simpleQuery.model = modelDescription.model;
                state.detailQuery = detailQuery;
                state.detailParameters = modelDescription.getDetailParametersOrDefaults;
            }
            return state;
        }));
    };
    RadioButtonFieldComponent.prototype.onChanges = function () {
        var _this = this;
        var parameters = this.field.params['parameters']
            ? this.field.params['parameters'].map(function (item) { return new ParameterField().deserialize(item); })
            : [];
        var inputs = this.field.params['inputs']
            ? this.field.params['inputs'].map(function (item) { return new Input().deserialize(item); })
            : [];
        var state = {
            currentValue: this.currentValue,
            optionsType: this.field.params['options_type'],
            resourceName: this.field.params['resource'],
            valueField: this.field.params['value_field'],
            labelField: this.field.params['label_field'],
            labelInput: this.field.params['label_field_input']
                ? new Input().deserialize(this.field.params['label_field_input'])
                : undefined,
            subtitleField: this.field.params['subtitle_field'],
            subtitleInput: this.field.params['subtitle_input']
                ? new Input().deserialize(this.field.params['subtitle_input'])
                : undefined,
            iconField: this.field.params['icon_field'],
            iconInput: this.field.params['icon_input'] ? new Input().deserialize(this.field.params['icon_input']) : undefined,
            colorField: this.field.params['color_field'],
            colorInput: this.field.params['color_input']
                ? new Input().deserialize(this.field.params['color_input'])
                : undefined,
            query: this.field.params['query']
                ? new ListModelDescriptionQuery().deserialize(this.field.params['query'])
                : undefined,
            columns: this.field.params['columns']
                ? this.field.params['columns'].map(function (item) { return deserializeDisplayField(item); })
                : undefined,
            parameters: parameters,
            inputs: inputs,
            sortingField: this.field.params['sorting_field'],
            sortingAsc: this.field.params['sorting_asc'],
            options: this.field.params['options']
        };
        if (this.updateFieldSubscription) {
            this.updateFieldSubscription.unsubscribe();
        }
        this.updateFieldSubscription = this.applyStateDefaults(state)
            .pipe(untilDestroyed(this))
            .subscribe(function (newState) {
            newState.params = applyParamInputs({}, state.inputs, { context: _this.context, parameters: state.parameters });
            var changes = _this.getChanges(newState);
            _this.state = changes.newState;
            if (changes.currentValueChanged ||
                changes.optionsTypeChanged ||
                changes.queryChanged ||
                changes.columnsChanged ||
                changes.sortingChanged ||
                changes.optionsChanged) {
                _this.updateOptions(changes.newState);
            }
        });
    };
    RadioButtonFieldComponent.prototype.updateOptions = function (state) {
        if (this.recordOptionsSubscription) {
            this.recordOptionsSubscription.unsubscribe();
            this.recordOptionsSubscription = undefined;
        }
        if (state.optionsType == OptionsType.Query) {
            if (this.modelSelectSource) {
                this.modelSelectSource.init({
                    resource: state.resourceName,
                    query: state.query,
                    queryParameters: state.parameters || [],
                    detailQuery: state.detailQuery,
                    detailQueryParameters: state.detailParameters || [],
                    columns: state.columns,
                    valueField: state.valueField,
                    nameField: state.labelField,
                    nameInput: state.labelInput,
                    subtitleField: state.subtitleField,
                    subtitleInput: state.subtitleInput,
                    iconField: state.iconField,
                    iconInput: state.iconInput,
                    colorField: state.colorField,
                    colorInput: state.colorInput,
                    params: state.params,
                    sortingField: state.sortingField,
                    sortingAsc: state.sortingAsc,
                    context: this.context,
                    contextElement: this.contextElement
                });
                this.modelSelectSource.reset();
                this.modelSelectSource.loadMore();
            }
            this.source = this.modelSelectSource;
            this.recordOptions$.next([]);
            this.cd.markForCheck();
        }
        else {
            var options = [];
            if (state.options) {
                options.push.apply(options, state.options);
            }
            this.source = undefined;
            this.recordOptions$.next(options);
            this.cd.markForCheck();
        }
        this.updateValueOption();
    };
    RadioButtonFieldComponent.prototype.updateValueOption = function () {
        var _this = this;
        if (this.valueOptionSubscription) {
            this.valueOptionSubscription.unsubscribe();
            this.valueOptionSubscription = undefined;
        }
        var obs;
        if (this.source) {
            if (this.modelSelectSource) {
                obs = this.modelSelectSource.fetchByValue(this.currentValue).pipe(map(function (option) {
                    if (!option) {
                        return;
                    }
                    var optionSingle = isArray(option) ? option[0] : option;
                    return optionFromGxOption(optionSingle);
                }));
            }
        }
        else {
            obs = this.recordOptions$.pipe(map(function (options) {
                var option = options.find(function (item) { return item.value == _this.currentValue; });
                if (!option) {
                    if (_this.currentValue) {
                        return { name: _this.currentValue, value: _this.currentValue };
                    }
                    else {
                        return;
                    }
                }
                return option;
            }));
        }
        if (!obs) {
            return;
        }
        this.valueOptionSubscription = obs.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.valueOption = result;
            _this.cd.markForCheck();
        }, function () {
            _this.valueOption = undefined;
            _this.cd.markForCheck();
        });
    };
    RadioButtonFieldComponent.prototype.backgroundCustomColor = function (color) {
        try {
            var clr = Color(color);
            return clr.alpha(0.1).string();
        }
        catch (e) {
            return null;
        }
    };
    return RadioButtonFieldComponent;
}(FieldComponent));
export { RadioButtonFieldComponent };
registerFieldComponent(FieldType.RadioButton, RadioButtonFieldComponent);
